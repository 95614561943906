
// https://www.digitalocean.com/community/tutorials/how-to-handle-file-uploads-in-vue-2
import { computed, defineComponent, reactive, ref } from "vue";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

interface IFile {
  name: string;
  size: number;
  type: string;
}

interface IState {
  uploadError: null;
  currentStatus: number;
  uploadFieldName: string;
  files: IFile[];
}

export default defineComponent({
  props: {
    showfiles: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "*.*",
    },
  },
  emits: ["filesAdded", "fileRemoved"],
  setup(props, context) {
    const state = reactive<IState>({
      files: [],
      uploadError: null,
      currentStatus: STATUS_INITIAL,
      uploadFieldName: "files",
    });

    const isInitial = computed(() => state.currentStatus === STATUS_INITIAL);
    const isSaving = computed(() => state.currentStatus === STATUS_SAVING);
    const isSuccess = computed(() => state.currentStatus === STATUS_SUCCESS);
    const isFailed = computed(() => state.currentStatus === STATUS_FAILED);

    const removeFile = (fileName: string) => {
      state.files = state.files.filter((f) => f.name !== fileName);
      context.emit("fileRemoved", fileName);
    };

    const filesChange = (fieldName: string, fileList: File[]) => {
      if (!fileList.length) return;

      Array.from(Array(fileList.length).keys()).map((x) => {
        state.files.push({
          name: fileList[x].name,
          size: fileList[x].size,
          type: fileList[x].type,
        });
        return x;
      });
      context.emit("filesAdded", fileList);
    };

    return {
      state,
      isInitial,
      isSaving,
      isSuccess,
      isFailed,
      filesChange,
      removeFile,
    };
  },
});
