
import router from "@/router";
import { toLocalDateString } from "@/utils";
import { defineComponent, onMounted, computed } from "vue";
import { editionstore } from "@/stores";

export default defineComponent({
  setup() {
    const editions = computed(() => editionstore.editions);

    onMounted(async () => {
      await editionstore.fetchEditions();
    });

    const editEdition = async (editionId: string) => {
      router.push({ name: "edition", params: { editionId } });
    };

    const deleteEdition = async (editionId: string) => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Vill du verkligen ta bort denna utgåva?")) {
        await editionstore.deleteEdition(editionId);
      }
    };

    return { editions, toLocalDateString, editEdition, deleteEdition };
  },
});
