import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  Router,
  RouteLocationNormalized,
  NavigationGuardNext,
} from "vue-router";

// Layouts
import DefaultLayout from "@/views/layouts/Default.vue";
import EmptyLayout from "@/views/layouts/Empty.vue";

// Shared
import NotFoundView from "@/views/404.vue";

// Views
import TipsView from "@/views/Tips.vue";
import WriteView from "@/views/Write.vue";
import AdvertisementView from "@/views/advertisement/Advertisement.vue";
import Landing from "@/views/Landing.vue";
import ProductionPlan from "@/views/advertisement/Plan.vue";
import AdCosts from "@/views/advertisement/Costs.vue";
import AdBookingRequest from "@/views/advertisement/Request.vue";

import PhotoComp2021 from "@/views/events/PhotoComp2021.vue";

import LoginView from "@/views/account/Login.vue";
import UnauthorizedView from "@/views/Unauthorized.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "tips",
    component: TipsView,
  },
  {
    path: "write",
    component: WriteView,
  },
  {
    path: "advertisement",
    component: AdvertisementView,
  },
  {
    path: "advertisement/plan",
    component: ProductionPlan,
  },
  {
    path: "advertisement/costs",
    component: AdCosts,
  },
  {
    path: "advertisement/request",
    component: AdBookingRequest,
  },
  {
    path: "home",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/Home.vue"),
  },
  // {
  //   path: "about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  // },
  // {
  //   path: "login",
  //   name: "Login",
  //   component: LoginView,
  // },
  {
    path: "unauthorized",
    name: "Unauthorized",
    component: UnauthorizedView,
  },
  {
    path: "foto2021",
    name: "foto2021",
    component: PhotoComp2021,
  },
];

export default routes;
