import { RouteRecordRaw } from "vue-router";

// Layouts
import EmptyLayout from "@/views/layouts/Empty.vue";

// Admin - Users
import AccountsView from "@/views/management/UserAccounts/Accounts.vue";
import EditAccountView from "@/views/management/UserAccounts/EditAccount.vue";
import CreateAccountView from "@/views/management/UserAccounts/CreateAccount.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "users",
    component: EmptyLayout,
    children: [
      {
        path: "accounts",
        name: "useraccounts",
        component: AccountsView,
        meta: { secure: true },
      },
      {
        path: "editaccount/:userId",
        name: "editaccount",
        component: EditAccountView,
        meta: { secure: true },
      },
      {
        path: "createaccount",
        name: "createaccount",
        component: CreateAccountView,
        meta: { secure: true },
      },
    ],
  },
];

export default routes;
