
import { defineComponent, reactive } from "vue";
import { IAdPlan } from "@/services/api";
import { toLocalDateString } from "@/utils";
import { loadspinner } from "@/components";
import adstore from "@/stores/modules/adstore";
import AdNav from "./AdNav.vue";

interface State {
  plans: IAdPlan[];
  loading: boolean;
}

export default defineComponent({
  components: { loadspinner, AdNav },
  setup() {
    const state = reactive<State>({
      plans: adstore.plans,
      loading: false,
    });

    return {
      state,
      toLocalDateString,
    };
  },
});
