
import { computed, defineComponent, PropType, reactive } from "vue";

interface ISorting {
  field: string;
  isDesc: boolean;
}

export default defineComponent({
  props: {
    field: {
      type: String,
      required: true,
    },
    sorting: {
      type: Object as PropType<ISorting>,
      required: true,
    },
  },
  emits: ["changed"],
  setup(props, context) {
    const state = reactive({
      isDesc: false,
    });

    const isDesc = computed(() => state.isDesc);

    const isEnabled = computed(() => props.sorting.field === props.field);

    const asc = () => {
      state.isDesc = false;
      context.emit("changed", state.isDesc, props.field);
    };

    const desc = () => {
      state.isDesc = true;
      context.emit("changed", state.isDesc, props.field);
    };

    return { isDesc, asc, desc, isEnabled };
  },
});
