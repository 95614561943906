import { RouteRecordRaw } from "vue-router";

// Layouts
import EmptyLayout from "@/views/layouts/Empty.vue";

import AdvertisersView from "@/views/management/Advertising/Advertisers.vue";
import EditAdvertiserView from "@/views/management/Advertising/EditAdvertiser.vue";

import AdBookingsView from "@/views/management/Advertising/Adbookings.vue";
import EditAdBookingView from "@/views/management/Advertising/EditAdBooking.vue";

import EditionsView from "@/views/management/Editions/Editions.vue";
import EditionEditor from "@/views/management/Editions/EditionEditor.vue";

import Contents from "@/views/management/Content/Contents.vue";
import ContentEditor from "@/views/management/Content/EditContent.vue";
import ContentOverview from "@/views/management/Content/Overview.vue";

import OcrService from "@/views/authorservices/OcrService.vue";
import SpeechToText from "@/views/authorservices/SpeechToText.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "advertising/",
    component: EmptyLayout,
    children: [
      {
        path: "advertisers",
        name: "advertisers",
        component: AdvertisersView,
      },
      {
        path: "advertiser/:advertiserId",
        name: "advertiser",
        component: EditAdvertiserView,
      },
      {
        path: "createadvertiser",
        name: "createadvertiser",
        component: EditAdvertiserView,
      },
      {
        path: "adbookings",
        name: "adbookings",
        component: AdBookingsView,
      },
      {
        path: "createAdBooking",
        name: "createAdBooking",
        component: EditAdBookingView,
      },
      {
        path: "adbooking/:bookingId",
        name: "editAdBooking",
        component: EditAdBookingView,
      },
    ],
  },
  {
    path: "editions/",
    component: EmptyLayout,
    children: [
      {
        path: "",
        name: "editions",
        component: EditionsView,
      },
      {
        path: "edition/:editionId",
        name: "edition",
        component: EditionEditor,
      },
      {
        path: "edition",
        name: "createedition",
        component: EditionEditor,
      },
    ],
  },
  {
    path: "content/",
    component: EmptyLayout,
    children: [
      {
        path: "",
        name: "contents",
        component: Contents,
      },
      {
        path: "overview",
        name: "contentoverview",
        component: ContentOverview,
      },
      {
        path: "edit/:contentId",
        name: "editcontent",
        component: ContentEditor,
      },
      {
        path: "create",
        name: "createcontent",
        component: ContentEditor,
      },
    ],
  },
  {
    path: "tools/",
    component: EmptyLayout,
    children: [
      {
        path: "ocr",
        name: "ocr",
        component: OcrService,
      },
      {
        path: "stt",
        name: "stt",
        component: SpeechToText,
      },
    ],
  },
];

export default routes;
