
import { api, IEdition, IEditionOverview } from "@/services/api";
import { defineComponent, onMounted, reactive } from "vue";
import router from "@/router";

interface State {
  editions: IEdition[];
  selectedEdition: string;
  editionOverview: IEditionOverview | null;
}

export default defineComponent({
  setup() {
    const state = reactive<State>({
      editions: [],
      selectedEdition: "",
      editionOverview: null,
    });

    onMounted(async () => {
      state.editions = await api.getEditions();
    });

    const getSelectedEdition = async (editionId: string) => {
      state.editionOverview = await api.getEditionOverview(editionId);
    };

    const editionSelected = async () => {
      await getSelectedEdition(state.selectedEdition);
      localStorage.setItem(
        "content.overview.lastSelectedEdition",
        state.selectedEdition
      );
    };

    const getStoredSelectedEdition = () => {
      const val = localStorage.getItem("content.overview.lastSelectedEdition");
      if (val !== null) {
        state.selectedEdition = val;
        getSelectedEdition(state.selectedEdition);
      }
    };

    const editContent = async (contentId: string) => {
      router.push({ name: "editcontent", params: { contentId } });
    };

    getStoredSelectedEdition();

    return {
      state,
      editionSelected,
      editContent,
    };
  },
});
