
import {
  defineComponent,
  reactive,
  computed,
  ComputedRef,
  onMounted,
} from "vue";
import { editionstore } from "@/stores";
import { getEmptyGuid } from "@/utils";
import { IEdition } from "@/services";

export default defineComponent({
  name: "EditionSelector",
  inheritAttrs: true,
  components: {},
  emits: ["selected"],
  setup(props, context) {
    const editions: ComputedRef<IEdition[]> = computed(
      () => editionstore.editions
    );

    onMounted(async () => {
      if (editionstore.editions.length < 1) {
        await editionstore.fetchEditions();
      }
    });

    const selectEdition = async (editionId: any) => {
      context.emit("selected", editionId);
    };

    return {
      editions,
      selectEdition,
    };
  },
});
