
import { getNewGuid } from "@/utils";
import { defineComponent, reactive } from "vue";

interface IState {
  id: string;
  file: File | null;
  fileName: string;
  loadingThumbnail: boolean;
}

export default defineComponent({
  props: {
    accept: {
      type: String,
      default: "*.*",
    },
  },
  emits: ["fileAdded", "fileRemoved"],
  setup(props, context) {
    const state = reactive<IState>({
      id: getNewGuid(),
      file: null,
      fileName: "",
      loadingThumbnail: false,
    });

    const getPreviewElement = (): HTMLImageElement | null => {
      const el = document.getElementById(
        `preview-${state.id}`
      ) as HTMLImageElement;
      return el;
    };

    const setNoPreview = () => {
      const el = getPreviewElement();
      if (el) {
        el.src = "";
      }
    };

    const getFile = () => {
      if (state.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const el = getPreviewElement();
          if (el) {
            el.src = e.target?.result as string;
          }
        };

        reader.readAsDataURL(state.file);
      }
    };

    const filesChange = (fileList: File[]) => {
      const file = fileList[0];

      if (file === undefined) {
        state.file = null;
        state.fileName = "";
        context.emit("fileRemoved");
        setNoPreview();
        return;
      }
      state.loadingThumbnail = true;
      context.emit("fileAdded", fileList);
      state.file = file;
      state.fileName = file.name;
      getFile();
      state.loadingThumbnail = false;
    };

    return {
      state,
      filesChange,
    };
  },
});
