
import { defineComponent } from "vue";
import router from "@/router";
import LoginButton from "@/components/account/LoginButton.vue";
import { authstore, profilestore } from "@/stores";

export default defineComponent({
  components: {
    LoginButton,
  },
  setup() {
    const isAuthed = (): boolean => {
      return authstore.isAuthenticated;
    };

    const userName = (): string => {
      return profilestore.fullname;
    };

    const logout = async (): Promise<void> => {
      return authstore.logout();
    };

    const toggleAppMenu = async ():Promise<void> => {
      return profilestore.toggleAppMenu();
    };

    return {
      isAuthed,
      userName,
      logout,
      toggleAppMenu,
    };
  },
});

// @Options({
//   components: { LoginButton },
// })
// export default class NavMenu extends Vue {
//   isAuthed(): boolean {
//     return authstore.isAuthenticated;
//   }

//   userName(): string {
//     return profilestore.fullname;
//   }

//   async logout() {
//     authstore.logout();
//   }

//   async login() {
//     const currentPath = router.currentRoute.value.path;
//     router.push({ name: "Login", query: { redirect: currentPath } });
//   }

//   async toggleAppMenu() {
//     profilestore.toggleAppMenu();
//   }
// }
