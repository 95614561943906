
// eslint-disable-next-line object-curly-newline
import { defineComponent, reactive, onMounted, watch } from "vue";
import { Modal } from "bootstrap";
import LoginForm from "@/components/account/LoginForm.vue";
import { authstore } from "@/stores";
import { getNewGuid } from "@/utils";

interface State {
  modal: Modal | null;
  id: string;
}

export default defineComponent({
  props: {
    type: { type: String, default: "button" },
  },
  components: {
    LoginForm,
  },
  setup(props, context) {
    const state = reactive<State>({
      modal: null,
      id: getNewGuid(),
    });

    const authed = (): boolean => authstore.isAuthenticated;

    watch(authed, () => {
      if (state.modal && authed() === true) {
        state.modal.hide();
      }
    });

    onMounted(() => {
      const el = document.getElementById(state.id);
      if (el !== null) {
        state.modal = new Modal(el as HTMLElement, {
          // backdrop: "static",
          backdrop: props.type === "button" ? "static" : false,
          keyboard: false,
        });
      }
    });

    const showForm = (e: Event) => {
      e.preventDefault();
      if (state.modal) {
        state.modal.show();
      }
    };

    return {
      state,
      showForm,
      authed,
    };
  },
});
