import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42893382"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav nav-tabs" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "nav-link",
        to: "/advertisement"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Information")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_3, [
      _createVNode(_component_router_link, {
        class: "nav-link",
        to: "/advertisement/plan"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Utgivningsplan")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createVNode(_component_router_link, {
        class: "nav-link",
        to: "/advertisement/costs"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Annonsformat och priser")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_5, [
      _createVNode(_component_router_link, {
        class: "nav-link",
        to: "/advertisement/request"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Bokningsförfrågan för annonsplats")
        ]),
        _: 1
      })
    ])
  ]))
}