
/* eslint-disable max-classes-per-file */
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
} from "vue";
import { api, IAdCost, IAdRequest, IAdRequestOption } from "@/services/api";
import { getNewGuid, getValidationErrors, objectValidator } from "@/utils";
import { loadspinner, FieldErrors, TextField } from "@/components";
import { IsEmail, IsNotEmpty, Min } from "class-validator";
import AdNav from "./AdNav.vue";

class RequestOption implements IAdRequestOption {
  constructor(size: string, count: number) {
    this.size = size;
    this.count = count;
  }

  id = getNewGuid();

  @IsNotEmpty({ message: "Saknas" })
  size = "";

  @Min(1, { message: "Minst 1" })
  count = 1;
}

class Request implements IAdRequest {
  @IsNotEmpty({ message: "Saknas" })
  company = "";

  // @IsNotEmpty({ message: "Saknas" })
  organizationalNumber = "";

  @IsNotEmpty({ message: "Saknas" })
  contact = "";

  @IsNotEmpty({ message: "Saknas" })
  phone = "";

  @IsNotEmpty({ message: "Saknas" })
  @IsEmail(undefined, { message: "Ingen giltig e-postadress" })
  email = "";

  options: RequestOption[] = [];
}

interface State {
  form: Request;
  options: RequestOption[];
  errors: Record<string, any>;
  adformats: IAdCost[];
  busy: boolean;
  sent: boolean;
}

export default defineComponent({
  components: { AdNav, FieldErrors, TextField, loadspinner },
  setup() {
    const state = reactive<State>({
      form: new Request(),
      errors: [],
      options: [],
      adformats: [],
      busy: false,
      sent: false,
    });

    const setBusy = (v: boolean) => {
      state.busy = v;
    };

    const setSent = (v: boolean) => {
      state.sent = v;
    };

    const hasErrors = computed(() => Object.keys(state.errors).length !== 0);

    const validate = async () => {
      state.errors = await objectValidator(state.form);
    };

    const addOption = async () => {
      state.options.push(new RequestOption(state.adformats[0].description, 1));
    };

    const removeOption = async (option: RequestOption) => {
      state.options.splice(
        state.options.findIndex((o) => o.id === option.id),
        1
      );
    };

    onBeforeMount(async () => {
      state.adformats = await api.getAdvertisementCosts();
      state.options.push(new RequestOption(state.adformats[0].description, 1));
    });

    onMounted(async () => {
      await validate();
    });

    const send = async () => {
      if (hasErrors.value) return;
      setBusy(true);
      state.options.forEach((o) => state.form.options.push(o));

      api
        .sendAdvertismentRequest(state.form)
        .then((response) => {
          setBusy(false);
          setSent(true);
        })
        .catch((e) => {
          setBusy(false);
          setSent(false);
        });
    };

    return {
      state,
      send,
      validate,
      hasErrors,
      addOption,
      removeOption,
    };
  },
});
