import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/stores/rootstore";
import { api, IAuthRequest, IAuthResponse } from "@/services/api";

import profileStore from "./profile";

@Module({
  name: "auth",
  store,
  namespaced: true,
  dynamic: true,
})
class AuthStore extends VuexModule {
  private authentication: IAuthResponse | null = null;

  private errorMessage = "";

  @Mutation
  private setAuthenticatedUser(authResult: IAuthResponse) {
    this.authentication = authResult;
  }

  @Mutation
  private unSetAuthenticatedUser() {
    this.authentication = null;
  }

  @Mutation
  private clearErrorMessage() {
    this.errorMessage = "";
  }

  @Mutation
  private setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  get isAuthenticated(): boolean {
    return this.authentication !== null;
  }

  get error(): string {
    return this.errorMessage;
  }

  @Action
  async authenticateUser(request: IAuthRequest): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      api
        .login(request)
        .then(async (response: IAuthResponse) => {
          api.setBearer(response.bearerToken);
          this.context.commit("setAuthenticatedUser", response);
          this.clearErrorMessage();
          this.context.dispatch("startRefreshTimer", response.timeToLive);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          api.clearBearer();
          this.context.commit("unSetAuthenticatedUser");
          this.setErrorMessage("Felaktig inloggning.");
          resolve(false);
        });
    });
  }

  @Action
  async refresh() {
    api
      .refresh()
      .then(async (response: IAuthResponse) => {
        console.log("REFRESH SUCCESS");
        api.setBearer(response.bearerToken);
        this.context.commit("setAuthenticatedUser", response);
        this.clearErrorMessage();
        this.context.dispatch("startRefreshTimer", response.timeToLive);
        await profileStore.fetchProfile();
      })
      .catch((err) => {
        console.log("REFRESH FAIL");
        console.log(err);
        api.clearBearer();
        this.context.commit("unSetAuthenticatedUser");
      });
  }

  @Action
  async logout() {
    api.logout().then(async (success: boolean) => {
      if (success) {
        api.clearBearer();
        this.context.commit("unSetAuthenticatedUser");
        this.clearErrorMessage();
        await profileStore.disposeProfile();
      }
    });
  }

  @Action
  async startRefreshTimer(ttl: number) {
    if (ttl === undefined) {
      return;
    }

    const timeOut: number = ttl - 60;
    // console.log(`Refreshing token in ${timeOut} seconds.`);
    setTimeout(() => {
      this.context.dispatch("refresh");
    }, timeOut * 1000);
  }
}

export default getModule(AuthStore);
