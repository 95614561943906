
/* eslint-disable no-use-before-define */
import { defineComponent, ref, PropType, watch } from "vue";
import Datepicker from "vue3-datepicker";
import { Locale } from "date-fns";
import { sv } from "date-fns/locale";
import { toUtcDate } from "@/utils";

export default defineComponent({
  components: {
    Datepicker,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * `v-model` for selected date
     */
    modelValue: {
      type: Date as PropType<Date>,
      required: true,
    },
    /**
     * View on which the date picker should open. Can be either `year`, `month`, or `day`
     */
    startingView: {
      type: String as PropType<"year" | "month" | "day">,
      required: false,
      default: "day",
      validate: (v: unknown) =>
        typeof v === "string" && ["day", "month", "year"].includes(v),
    },
    /**
     * [`date-fns` locale object](https://date-fns.org/v2.16.1/docs/I18n#usage).
     * Used in string formatting (see default `monthHeadingFormat`)
     */
    locale: {
      type: Object as PropType<Locale>,
      required: false,
      default: sv,
    },
    label: {
      type: String,
      default: "",
      required: true,
    },
  },
  emits: ["change", "selected", "update:modelValue"],
  setup(props, context) {
    const picked = ref(toUtcDate(props.modelValue));

    watch(picked, () => {
      selected();
    });

    const selected = () => {
      context.emit("update:modelValue", picked.value);
      context.emit("selected", picked.value);
    };

    return {
      sv,
      picked,
      selected,
    };
  },
});
