import { faTint } from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosInstance } from "axios";
import { IHttpClient } from "./IHttpClient";

export default abstract class HttpClient implements IHttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseUrl: string) {
    this.instance = axios.create({
      baseURL: baseUrl,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
  }

  getBlob = (url: string): Promise<Blob> =>
    this.instance.get(url, { responseType: "blob", withCredentials: true });

  get = <TOut>(url: string, params: any = null): Promise<TOut> =>
    this.instance.get(url, {
      params,
      withCredentials: true,
    });

  post = <TIn, TOut>(
    url: string,
    data: TIn,
    progressHandler: any = null
  ): Promise<TOut> =>
    this.instance.post(url, data, {
      withCredentials: true,
      onUploadProgress: progressHandler,
    });

  put = <TIn, TOut>(url: string, data: TIn): Promise<TOut> =>
    this.instance.put(url, data, {
      withCredentials: true,
    });

  delete = (url: string, params?: any): Promise<void> =>
    this.instance.delete(url, { params, withCredentials: true });
}
