import { RouteRecordRaw } from "vue-router";

import EmptyLayout from "@/views/layouts/Empty.vue";

import LoginView from "@/views/account/Login.vue";
import UnauthorizedView from "@/views/Unauthorized.vue";

import ProfileView from "@/views/account/Profile.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "unauthorized",
    name: "Unauthorized",
    component: UnauthorizedView,
  },
  {
    path: "user",
    name: "",
    component: EmptyLayout,
    children: [
      {
        path: "profile",
        name: "profile",
        component: ProfileView
      }
    ]
  },
];

export default routes;
