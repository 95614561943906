
import router from "@/router";
import {
  api,
  IAdBooking,
  AdBookingStatusEnum,
  IAdFormat,
  IEdition,
  IAdvertiser,
} from "@/services/api";
import { TextField, FieldErrors, NumberField } from "@/components";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  watch,
} from "vue";
import { IsEmail, IsNotEmpty } from "class-validator";
import { getEmptyGuid, objectValidator } from "@/utils";

class Form implements IAdBooking {
  bookingId = "";

  @IsNotEmpty({ message: "Saknas" })
  advertiserId = "";

  @IsNotEmpty({ message: "Saknas" })
  editionId = "";

  @IsNotEmpty({ message: "Saknas" })
  formatId = "";

  listPrice = 0;

  discount = 0;

  price = 0;

  @IsNotEmpty({ message: "Saknas" })
  status: AdBookingStatusEnum = AdBookingStatusEnum.Unknown;

  comment = "";
}

interface State {
  form: Form;
  errors: Record<string, any>;
  formats: IAdFormat[];
  editions: IEdition[];
  advertisers: IAdvertiser[];
}

export default defineComponent({
  components: {
    // TextField,
    NumberField,
    FieldErrors,
  },
  setup() {
    const state = reactive<State>({
      form: new Form(),
      errors: {},
      formats: [],
      editions: [],
      advertisers: [],
    });

    const hasErrors = computed(() => Object.keys(state.errors).length !== 0);

    const isNew = computed(() => state.form.bookingId === getEmptyGuid());

    const validate = async () => {
      state.errors = await objectValidator(state.form);
    };

    const getPrice = () =>
      state.form.listPrice - state.form.listPrice * (state.form.discount / 100);

    const getAdBooking = async (adBookingId: string) => {
      const booking = await api.getAdBooking(adBookingId);
      state.form.bookingId = booking.bookingId;
      state.form.advertiserId = booking.advertiserId;
      state.form.editionId = booking.editionId;
      state.form.formatId = booking.formatId;
      state.form.listPrice = booking.listPrice;
      state.form.discount = booking.discount * 100;
      state.form.price = getPrice();
      state.form.status = booking.status;
      state.form.comment = booking.comment;
    };

    onBeforeMount(async () => {
      state.formats = await api.getAdFormats();
      state.advertisers = await api.getAdvertisers();
      state.editions = await api.getEditions();
    });

    onMounted(async () => {
      if (
        // eslint-disable-next-line operator-linebreak
        router.currentRoute.value.params &&
        router.currentRoute.value.params.bookingId
      ) {
        await getAdBooking(
          router.currentRoute.value.params.bookingId as string
        );
      } else {
        state.form.bookingId = getEmptyGuid();
      }
      await validate();
    });

    const cancel = () => router.push({ name: "adbookings" });

    watch(state.form, (form: Form) => {
      const f = state.formats.find((x) => x.formatId === form.formatId);
      if (f) {
        state.form.listPrice = f.price;
        const p = getPrice();
        console.log(p);
        state.form.price = getPrice();
      }
    });

    const getFormData = (): IAdBooking => ({
      bookingId: state.form.bookingId,
      advertiserId: state.form.advertiserId,
      editionId: state.form.editionId,
      formatId: state.form.formatId,
      listPrice: state.form.listPrice,
      discount: state.form.discount / 100,
      status: state.form.status,
      comment: state.form.comment,
    });

    const save = async () => {
      if (hasErrors.value) return;
      const data = getFormData();
      console.log(data);
      if (isNew.value) {
        await api.createAdBooking(data);
      } else {
        await api.saveAdBooking(data);
      }
      router.push({ name: "adbookings" });
    };

    return {
      state,
      hasErrors,
      validate,
      cancel,
      save,
      isNew,
    };
  },
});
