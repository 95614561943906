
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: "",
      required: true,
    },
    label: {
      type: String,
      default: "",
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    readonly: {
      type: String,
      required: false,
      default: "",
    },
  },
});
