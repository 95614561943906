/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

export const passwordValidationRegex = (): RegExp => {
  const regex = /^(?=(?:.*[A-Z]){2,})(?=(?:.*[a-z]){2,})(?=(?:.*\d){2,})(?=(?:.*[!@#$%^&*()\-_=+{};:,<.>]){2,})(?!.*(.)\1{2})([A-Za-z0-9!@#$%^&*()\-_=+{};:,<.>]{10,20})$/;
  return regex;
};

export const generatePassword = (): string => {
  const shuffleArray = (array: string[]): string[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  const generate = (): string[] => {
    const ucLetters = "ABCDEFGHIJKLMNOPQRSTUVXYZ";
    const lcLetters = "abcdefghijklmnopqrstuvxyz";
    const numbers = "0123456789";
    const special = "!@#$%^&*()-_=+{};:,<.>";

    const numuc = 4;
    const numlc = 4;
    const numn = 4;
    const nums = 4;

    let tmp = "";

    for (let i = 0; i < numuc; i++) {
      tmp += ucLetters[Math.floor(Math.random() * ucLetters.length)];
    }

    for (let i = 0; i < numlc; i++) {
      tmp += lcLetters[Math.floor(Math.random() * lcLetters.length)];
    }

    for (let i = 0; i < numn; i++) {
      tmp += numbers[Math.floor(Math.random() * numbers.length)];
    }

    for (let i = 0; i < nums; i++) {
      tmp += special[Math.floor(Math.random() * special.length)];
    }

    return tmp.split("");
  };

  let password = "";
  let result = false;

  while (result === false) {
    password = shuffleArray(generate()).join("");
    result = passwordValidationRegex().test(password);
  }

  return password;
};
