/* eslint-disable no-shadow */

export interface IEnumItem<T> {
  value: T;
  display: string;
}

export enum AdBookingStatusEnum {
  Booked = 100,
  Offered = 50,
  PreliminarilyBooked = 20,
  Unknown = 0,
}

export const AdvertisementBookingDescriptors = new Map<
  AdBookingStatusEnum,
  string
>([
  [AdBookingStatusEnum.Booked, "Bokad"],
  [AdBookingStatusEnum.Offered, "Offererad"],
  [AdBookingStatusEnum.PreliminarilyBooked, "Preliminärbokad"],
  [AdBookingStatusEnum.Unknown, "Okänd"],
]);

export const AdvertisementBookingStatuses = (): IEnumItem<number>[] => {
  const tmp: IEnumItem<number>[] = [];

  AdvertisementBookingDescriptors.forEach((v, k) => {
    tmp.push({ value: k, display: v });
  });

  const result = tmp.sort((a, b) => {
    if (a.value < b.value) return -1;
    return 1;
  });

  return result;
};

export enum ContentStatusEnum {
  New = 0,
  Planned = 10,
  Material = 11,
  Edited = 12,
  Correction = 14,
  Approval = 15,
  Compotition = 20,
  Printed = 21,
  Unplanned = 100,
}

export const ContentStatusDescriptors = new Map<ContentStatusEnum, string>([
  [ContentStatusEnum.New, "Ny"],
  [ContentStatusEnum.Planned, "Planerad"],
  [ContentStatusEnum.Material, "Material"],
  [ContentStatusEnum.Edited, "Redigeras"],
  [ContentStatusEnum.Correction, "Korrektur"],
  [ContentStatusEnum.Approval, "Godkännande"],
  [ContentStatusEnum.Compotition, "Sättning"],
  [ContentStatusEnum.Printed, "Tryckt"],
  [ContentStatusEnum.Unplanned, "Avplanerad"],
]);

export const GetContentStatuses = (): IEnumItem<number>[] => {
  const tmp: IEnumItem<number>[] = [];

  ContentStatusDescriptors.forEach((v, k) => {
    tmp.push({ value: k, display: v });
  });

  const result = tmp.sort((a, b) => {
    if (a.value < b.value) return -1;
    return 1;
  });

  return result;
};
