
import { authstore } from "@/stores";
import { computed, defineComponent, watch } from "vue";
import router from "@/router";
import LoginForm from "@/components/account/LoginForm.vue";

export default defineComponent({
  components: {
    LoginForm,
  },
  setup() {
    const redirect = () => {
      let redirectPath = "/";
      const redirectQuery = router.currentRoute.value.query.redirect;
      if (redirectQuery) {
        redirectPath = redirectQuery.toString();
      }
      router.push({ path: redirectPath });
    };

    const isAuthed = computed(() => authstore.isAuthenticated);

    watch(isAuthed, (authed) => {
      if (authed === true) {
        redirect();
      }
    });
  },
});
