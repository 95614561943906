/* eslint-disable no-throw-literal */
import configData from "../../../appsettings.json";

export class AppConfig {
  static AppVersion: string = configData.AppVersion;

  static ApiUrl: string = configData.ApiUrl;

  static Debug: boolean = configData.Debug;

  static ensureSettings(): void {
    this.isNotEmpty(this.AppVersion, "AppVersion");
    this.isNotEmpty(this.ApiUrl, "ApiUrl");
  }

  private static isNotEmpty(variable: any, variableName: string): void {
    if (variable === undefined || variable == null) {
      throw `The AppConfig setting '${variableName}' could not be found.`;
    }

    if (typeof variable === "string") {
      if (variable === "" || variable === "NOT_SET") {
        throw `The AppConfig setting '${variableName}' was empty.`;
      }
    }
  }
}
