
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      default: 0,
      required: true,
    },
    label: {
      type: String,
      default: "",
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
