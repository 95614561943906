import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store, { storagekey } from "@/stores/rootstore";

import { api, IEdition } from "@/services/api";
import { editionSorter, getEmptyGuid } from "@/utils";

@Module({
  name: "edition",
  store,
  dynamic: true,
  namespaced: true,
  // preserveState: localStorage.getItem(storagekey) !== null,
})
class EditionStore extends VuexModule {
  private knownEditions: IEdition[] = [];

  get editions(): IEdition[] {
    return this.knownEditions;
  }

  @Mutation
  private setEditions(editions: IEdition[]) {
    this.knownEditions = editions;
  }

  @Action
  async getEditions(): Promise<IEdition[]> {
    if (this.knownEditions.length === 0) {
      await this.fetchEditions();
    }
    return this.knownEditions;
  }

  @Action
  async getEdition(editionId: string): Promise<IEdition> {
    const edition = await api.getEdition(editionId);
    return edition;
  }

  @Action
  async saveEdition(edition: IEdition): Promise<void> {
    if (edition.editionId === getEmptyGuid()) {
      await api.createEdition(edition);
    } else {
      await api.saveEdition(edition);
    }
    await this.fetchEditions();
  }

  @Action
  async deleteEdition(editionId: string): Promise<void> {
    await api.deleteEdition(editionId);
    await this.fetchEditions();
  }

  @Action
  async fetchEditions() {
    const fetchedEditions = await api.getEditions();
    fetchedEditions.sort(editionSorter(true));
    this.context.commit("setEditions", fetchedEditions);
  }
}

export default getModule(EditionStore);
