import { registerDecorator, ValidationOptions, ValidationArguments } from "class-validator";
import { passwordValidationRegex } from "@/utils";

export function IsLaterThan(property: string, validationOptions?: ValidationOptions) {
  const isDateValue = (value: any): boolean => {
    const dateValue = new Date(value);

    if (dateValue instanceof Date === false) {
      return false;
    }

    return true;
  };

  return (object: Record<string, any>, propertyName: string) => {
    registerDecorator({
      name: 'isLaterThan',
      target: object.constructor,
      propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];

          if (!isDateValue(value) || !isDateValue(relatedValue)) {
            return false;
          }

          const dateValue = new Date(value);
          const relatedDateValue = new Date(relatedValue);
          return dateValue > relatedDateValue;
        }
      }
    });
  };
}

export function SameAs(property: string, validationOptions?: ValidationOptions) {
  return (object: Record<string, any>, propertyName: string) => {
    registerDecorator({
      name: 'sameAs',
      target: object.constructor,
      propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];

          return value === relatedValue;
        }
      }
    });
  };
}

export function MinArrayLength(minLength: number, validationOptions?: ValidationOptions) {
  return (object: Record<string, any>, propertyName: string) => {
    registerDecorator({
      name: "minArrayLength",
      target: object.constructor,
      propertyName,
      constraints: [minLength],
      options: validationOptions,
      validator: {
        validate(value: any[]) {
          return value.length >= minLength;
        }
      }
    });
  };
}

export function PasswordStrength(validationOptions?: ValidationOptions) {
  return (object: Record<string, any>, propertyName: string) => {
    registerDecorator({
      name: "passwordStrength",
      target: object.constructor,
      propertyName,
      options: validationOptions,
      validator: {
        validate(value: string) {
          return passwordValidationRegex().test(value);
        }
      }
    });
  };
}

export function IsNotNull(validationOptions?: ValidationOptions) {
  return (object: Record<string, any>, propertyName: string) => {
    registerDecorator({
      name: "isNotNull",
      target: object.constructor,
      propertyName,
      options: validationOptions,
      validator: {
        validate(value: unknown | null) {
          return value !== null;
        }
      }
    });
  };
}
