
import { api, IPasswordResetRequest, IUserAccount } from "@/services/api";
import {
  objectValidator,
  PasswordStrength,
  SameAs,
  generatePassword,
} from "@/utils";
import { Modal } from "bootstrap";
import { IsNotEmpty } from "class-validator";
import { defineComponent, reactive, onMounted, computed, PropType } from "vue";
import { TextField, FieldErrors } from "@/components";

class ChangePassForm implements IPasswordResetRequest {
  userId = "";

  @IsNotEmpty({ message: "Saknas" })
  @PasswordStrength({ message: "Lösenordet är inte starkt nog" })
  newPassword = "";

  @SameAs("newPassword", { message: "Lösenorden matchar inte" })
  newPasswordVerifikation = "";

  unlock = false;
}

interface State {
  userAccount: IUserAccount;
  changePasswordRequest: ChangePassForm;
  changePasswordModal: Modal | null;
  changePasswordErrors: Record<string, any>;
}

export default defineComponent({
  components: { TextField, FieldErrors },
  props: {
    userAccount: {
      type: Object as PropType<IUserAccount>,
      required: true,
    },
  },
  setup(props, context) {
    const state = reactive<State>({
      userAccount: props.userAccount,
      changePasswordRequest: new ChangePassForm(),
      changePasswordModal: null,
      changePasswordErrors: {},
    });

    const validatePasswordRequest = async () => {
      state.changePasswordErrors = await objectValidator(
        state.changePasswordRequest
      );
    };

    const setGeneratedPassword = async () => {
      const password = await generatePassword();
      state.changePasswordRequest.newPassword = password;
      state.changePasswordRequest.newPasswordVerifikation = password;
      validatePasswordRequest();
    };

    const showResetPassword = async () => {
      state.changePasswordRequest.userId = props.userAccount.userId;
      state.changePasswordModal?.show();
      await validatePasswordRequest();
    };

    const hasChangePasswordErrors = computed(
      () => Object.keys(state.changePasswordErrors).length !== 0
    );

    const changePassword = async () => {
      if (hasChangePasswordErrors.value) return;
      await api.resetAccountPassword({
        userId: state.changePasswordRequest.userId,
        newPassword: state.changePasswordRequest.newPassword,
        unlock: true,
      });
      state.changePasswordModal?.hide();
    };

    onMounted(async () => {
      const el = document.getElementById(`pwr_${state.userAccount.userId}`);
      if (el !== null) {
        state.changePasswordModal = new Modal(el as HTMLElement, {
          backdrop: "static",
          keyboard: false,
        });
      }
    });

    return {
      state,
      showResetPassword,
      validatePasswordRequest,
      setGeneratedPassword,
      changePassword,
    };
  },
});
