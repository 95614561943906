import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-367f4e02"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["disabled", "id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: _ctx.field,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("input", {
      disabled: _ctx.readonly,
      type: "number",
      step: "0.5",
      class: "form-control form-control-sm",
      id: _ctx.field,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 40, _hoisted_2)
  ]))
}