
import { defineComponent, PropType, computed, ComputedRef } from "vue";

export default defineComponent({
  props: {
    errors: {
      type: Array as PropType<Record<string, any>>,
    },
    field: {
      type: String,
      required: true,
    },
    single: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const getErrorsAsArray = (): string[] => {
      const errorDescriptions: string[] = [];

      if (props.errors === undefined) return errorDescriptions;
      if (props.errors[props.field] === undefined) return errorDescriptions;

      const ers = props.errors[props.field];
      const fields = Object.keys(ers);

      if (props.single) {
        errorDescriptions.push(ers[fields[fields.length - 1]]);
      } else {
        fields.forEach((f) => {
          errorDescriptions.push(ers[f]);
        });
      }

      return errorDescriptions;
    };

    const validationErrors = computed(() => getErrorsAsArray());
    return {
      validationErrors,
    };
  },
});
