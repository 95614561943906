
import { api, IUpdateUserAccount } from "@/services/api";
import { objectValidator, MinArrayLength } from "@/utils";
import { IsEmail, IsNotEmpty } from "class-validator";
import { defineComponent, reactive, onMounted, computed } from "vue";
import { TextField, FieldErrors } from "@/components";
import router from "@/router";

class Form implements IUpdateUserAccount {
  userId = "";

  @IsNotEmpty({ message: "Saknas" })
  firstname = "";

  @IsNotEmpty({ message: "Saknas" })
  lastname = "";

  @IsNotEmpty({ message: "Saknas" })
  @IsEmail(undefined, { message: "Inte en giltig e-postadress" })
  email = "";

  @MinArrayLength(1, { message: "Minst en roll måste väljas." })
  roles: string[] = [];
}

interface State {
  form: Form;
  errors: Record<string, any>;
  availableRoles: string[];
}

export default defineComponent({
  components: { TextField, FieldErrors },
  props: {},
  setup() {
    const state = reactive<State>({
      form: new Form(),
      errors: {},
      availableRoles: [],
    });

    const hasErrors = computed(() => Object.keys(state.errors).length !== 0);

    const validate = async () => {
      state.errors = await objectValidator(state.form);
    };

    const saveAccount = async () => {
      if (hasErrors.value) return;
      api.updateUserAccount(state.form.userId, state.form).then(() => {
        router.push({ name: "useraccounts" });
      });
    };

    onMounted(async () => {
      const userId = router.currentRoute.value.params.userId as string;
      const user = await api.getUserAccount(userId);
      state.form.userId = userId;
      state.form.firstname = user.firstname;
      state.form.lastname = user.lastname;
      state.form.email = user.email;
      state.form.roles = user.roles;
      state.availableRoles = await api.getRoles();
      await validate();
    });

    return {
      state,
      validate,
      saveAccount,
      hasErrors,
    };
  },
});
