
import {
  api,
  ICreateUserAccount,
  IPasswordResetRequest,
  IUserAccount,
} from "@/services/api";
import { objectValidator, generatePassword } from "@/utils";
import { Modal } from "bootstrap";
import { IsNotEmpty } from "class-validator";
import { defineComponent, reactive, onMounted, computed } from "vue";
import { TextField, FieldErrors } from "@/components";
import router from "@/router";
import ChangePasswordButton from "./ChangePassword.vue";

interface State {
  accounts: IUserAccount[];
  newaccount: ICreateUserAccount | null;
  roles: string[];
}

export default defineComponent({
  components: {
    ChangePasswordButton,
  },
  setup() {
    const state = reactive<State>({
      accounts: [],
      newaccount: null,
      roles: [],
    });

    const getRoles = async () => {
      state.roles = await api.getRoles();
    };

    const getAccounts = async () => {
      state.accounts = await api.getUserAccounts();
    };

    const editAccount = async (userId: string) => {
      router.push({ name: "editaccount", params: { userId } });
    };

    onMounted(async () => {
      await getRoles();
      await getAccounts();
    });

    return {
      state,
      editAccount,
    };
  },
});
