
// eslint-disable-next-line max-classes-per-file
import { computed, defineComponent, onMounted, reactive } from "vue";
import { TextField, FieldErrors, ImageUpload } from "@/components"; // @ is an alias to /src
import { api } from "@/services/api";
import { IsEmail, IsNotEmpty, Matches } from "class-validator";
import { getNewGuid, IsNotNull, objectValidator } from "@/utils";

class PictureForm {
  @IsNotNull({ message: "Saknas" })
  picture: File | null = null;

  @IsNotEmpty({ message: "Saknas" })
  description = "";

  fileName = "";

  id = getNewGuid();

  errors: Record<string, any> = [];
}

class PhotoCompForm {
  constructor() {
    this.pictures.push(new PictureForm());
  }

  @IsNotEmpty({ message: "Saknas" })
  name = "";

  @IsNotEmpty({ message: "Saknas" })
  @IsEmail(undefined, { message: "Ingen giltig e-postadress" })
  email = "";

  @IsNotEmpty({ message: "Saknas" })
  @Matches(new RegExp("^[0-9-]*$"), { message: "Endast siffror och -" })
  phone = "";

  @IsNotEmpty({ message: "Saknas" })
  @Matches(new RegExp("^\\d{1,5}$"), { message: "Fel format" })
  psk = "";

  pictures: PictureForm[] = [];

  errors: Record<string, any> = [];
}

interface State {
  form: PhotoCompForm;
  sent: boolean;
  codecorrect: boolean;
  code: string;
  codeValidationMessage: string;
  canContribute: boolean;
  submitInProgress: boolean;
  submitProgress: number;
  submitMessage: string;
  submitError: string;
}

export default defineComponent({
  components: {
    TextField,
    FieldErrors,
    ImageUpload,
  },
  setup() {
    const state = reactive<State>({
      submitProgress: 0,
      submitInProgress: false,
      form: new PhotoCompForm(),
      sent: false,
      codecorrect: false,
      code: "",
      codeValidationMessage: "",
      canContribute: true,
      submitMessage: "",
      submitError: "",
    });

    const hasErrors = computed(() => {
      let errorish = false;
      if (Object.keys(state.form.errors).length !== 0) {
        errorish = true;
      }
      state.form.pictures.forEach((p) => {
        if (Object.keys(p.errors).length !== 0) {
          errorish = true;
        }
      });

      return errorish;
    });

    const validateForm = async () => {
      state.form.errors = await objectValidator(state.form);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < state.form.pictures.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        state.form.pictures[i].errors = await objectValidator(
          state.form.pictures[i]
        );
      }
      // eslint-disable-next-line no-use-before-define
      state.canContribute = await checkIfCanContribute();
    };

    onMounted(async () => {
      await validateForm();
    });

    const imageRemoved = async (pictureId: string) => {
      const idx = state.form.pictures.findIndex((x) => x.id === pictureId);
      state.form.pictures[idx].picture = null;
      state.form.pictures[idx].fileName = "";

      state.form.pictures[idx].errors = await objectValidator(
        state.form.pictures[idx]
      );
    };

    const imageAdded = async (files: File[], pictureId: string) => {
      const idx = state.form.pictures.findIndex((x) => x.id === pictureId);

      const file = files[0];
      state.form.pictures[idx].picture = file;
      state.form.pictures[idx].fileName = file.name;

      // const reader = new FileReader();
      // reader.readAsDataURL(file);

      // const image = new Image();

      // image.onload = () => {
      //   console.log("height:", image.naturalHeight);
      //   console.log("width:", image.naturalWidth);
      // };

      // reader.onload = (e) => {
      //   image.src = e.target?.result as string;
      // };

      state.form.pictures[idx].errors = await objectValidator(
        state.form.pictures[idx]
      );
    };

    const onProgressChange = (progressEvent: any) => {
      if (progressEvent.lengthComputable) {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        state.submitProgress = progress;
      }
    };

    const checkIfCanContribute = async (): Promise<boolean> => {
      const result = await api.photoCompCompetitorExists({
        name: state.form.name,
        email: state.form.email,
        psk: state.form.psk,
        phone: state.form.phone,
      });
      return !result;
    };

    const uploadImage = async (data: FormData): Promise<boolean> =>
      new Promise<boolean>((res) => {
        api
          .photoCompAddImage(data, onProgressChange)
          .then(() => {
            res(true);
          })
          .catch(() => {
            res(false);
          });
      });

    const sendImages = async (competitionContributionId: string) => {
      let imageCount = 1;

      // eslint-disable-next-line no-restricted-syntax
      for await (const picture of state.form.pictures) {
        state.submitMessage = `Laddar upp bild ${imageCount}`;
        state.submitProgress = 0;
        const data = new FormData();
        data.append("ContributionId", competitionContributionId);
        data.append("Image", picture.picture as Blob);
        data.append("Description", picture.description);
        const sendResult = await uploadImage(data);
        if (sendResult) {
          // eslint-disable-next-line no-plusplus
          imageCount++;
        } else {
          state.submitError = `Ett fel uppstod när bilden '${picture.fileName}' skulle laddas upp.`;
          state.submitInProgress = false;
        }
      }
    };

    const send = async () => {
      if (state.form) {
        state.submitInProgress = true;
        state.submitMessage = "Skickar in bidraget..";

        const competitionContributionId = await api.photoCompAddContribution({
          name: state.form.name,
          email: state.form.email,
          phone: state.form.phone,
          psk: state.form.psk,
        });

        await sendImages(competitionContributionId);

        state.submitMessage = "Alla bilder skickade";

        setTimeout(() => {
          state.submitInProgress = false;
          state.sent = true;
        }, 1500);
      }
    };

    const hasMaxPictures = computed(() => state.form.pictures.length >= 3);

    const hasMinPictures = computed(() => state.form.pictures.length < 2);

    const addPicture = () => {
      state.form.pictures.push(new PictureForm());
      validateForm();
    };

    const deletePicture = (pictureId: string) => {
      const idx = state.form.pictures.findIndex((x) => x.id === pictureId);
      state.form.pictures.splice(idx, 1);
    };

    const pretty = (val: any): string => val;

    const validateCode = async () => {
      state.codecorrect = await api.photoCompValidateCode(state.code);
      if (state.codecorrect === false) {
        state.codeValidationMessage = "Fel tävlingskod";
      }
    };

    return {
      state,
      validateForm,
      hasErrors,
      imageAdded,
      pretty,
      hasMaxPictures,
      hasMinPictures,
      addPicture,
      deletePicture,
      imageRemoved,
      send,
      validateCode,
    };
  },
});
