<template>
  <nav
    id="sidebarmenu"
    class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
  >
    <div class="position-sticky pt-3">
      <ul class="nav flex-column">
        <li>
          <h6 class="nav-link">System</h6>
        </li>
        <li>
          <router-link to="/admin/users/accounts" class="nav-link"
            >Användare</router-link
          >
        </li>
        <li>
          <h6 class="nav-link">Tidningen</h6>
        </li>
        <li>
          <router-link to="/mgmt/editions" class="nav-link"
            >Utgåvor</router-link
          >
        </li>
        <li>
          <h6 class="nav-link">Annonser</h6>
        </li>
        <li>
          <router-link to="/mgmt/advertising/advertisers" class="nav-link"
            >Annonsörer</router-link
          >
          <router-link to="/mgmt/advertising/adbookings" class="nav-link"
            >Annonsbokningar</router-link
          >
        </li>
        <li>
          <h6 class="nav-link">Innehåll</h6>
        </li>
        <li>
          <router-link to="/mgmt/content/overview" class="nav-link"
            >Översikt</router-link
          >
        </li>
        <li>
          <router-link to="/mgmt/content" class="nav-link"
            >Innehåll</router-link
          >
        </li>
        <li>
          <h6 class="nav-link">Verktyg</h6>
        </li>
        <li>
          <router-link to="/mgmt/tools/ocr" class="nav-link"
            >OCR</router-link
          >
        </li>
        <li>
          <router-link to="/mgmt/tools/stt" class="nav-link"
            >Tal till text</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
</template>
<style lang="scss" scoped>
h6.nav-link {
  background-color: #bbbbbb;
  margin-bottom: 0;
}
a.nav-link {
  margin-top: 0;
  font-weight: 400;
}
a.nav-link:hover {
  background-color: rgba(255, 208, 0, 0.438);
  font-weight: 700;
}
</style>
