
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
import router from "@/router";
import { api, IAdvertiser } from "@/services";
import { computed, defineComponent, onMounted, reactive } from "vue";

interface State {
  advertisers: IAdvertiser[];
  filter: string;
}

export default defineComponent({
  setup() {
    const state = reactive<State>({
      advertisers: [],
      filter: "",
    });

    const applyFilter = async () => {
      console.log(state.filter);
    };

    const filteredAdvertisers = computed<IAdvertiser[]>(() => {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let filtered = state.advertisers.sort((a, b) =>
        a.company
          .toLocaleLowerCase()
          .localeCompare(b.company.toLocaleLowerCase())
      );

      const filter = state.filter.toLowerCase();

      if (filter.length > 0) {
        filtered = state.advertisers.filter(
          (a) =>
            a.company?.toLowerCase().includes(filter) ||
            a.organizationalNumber?.toLowerCase().includes(filter) ||
            a.address?.toLowerCase().includes(filter) ||
            a.zipCode?.toLowerCase().includes(filter) ||
            a.city?.toLowerCase().includes(filter) ||
            a.contact?.toLowerCase().includes(filter) ||
            a.phone?.toLowerCase().includes(filter) ||
            a.email?.toLowerCase().includes(filter) ||
            a.alternativeContact?.toLowerCase().includes(filter) ||
            a.comments?.toLowerCase().includes(filter)
        );
      }

      return filtered;
    });

    const editAdvertiser = async (advertiserId: string) => {
      router.push({ name: "advertiser", params: { advertiserId } });
    };

    const removeAdvertiser = (advertiserId: string) => {
      state.advertisers = state.advertisers.filter(
        (a) => a.advertiserId !== advertiserId
      );
    };

    const deleteAdvertiser = (advertiser: IAdvertiser) => {
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm(`Vill du verkligen ta bort annonsören ${advertiser.company}?`)
      ) {
        api.deleteAdvertiser(advertiser.advertiserId as string).then(() => {
          removeAdvertiser(advertiser.advertiserId as string);
        });
      }
    };

    onMounted(async () => {
      state.advertisers = await api.getAdvertisers();
    });

    return {
      state,
      applyFilter,
      filteredAdvertisers,
      editAdvertiser,
      deleteAdvertiser,
    };
  },
});
