import { Locale } from "date-fns";
import {
  zonedTimeToUtc,
  utcToZonedTime,
  format,
  getTimezoneOffset,
} from "date-fns-tz";
import { sv } from "date-fns/locale";

const locales: Record<string, Locale> = { sv };

const getTimeZone = (): string => {
  // const split = new Date().toString().split(" ");
  // const timeZoneFormatted: string = `${split[split.length - 2]} ${
  //   split[split.length - 1]
  // }`;
  // return timeZoneFormatted;
  const timezone = new Date().toString().match(/([A-Z]+[+-][0-9]+.*)/)![1];
  return timezone;
};

export const toUtcDate = (date: Date): Date =>
  zonedTimeToUtc(date, getTimeZone());

export const toLocalDateString = (date: Date | string) => {
  if (date) {
    
    let d: Date | null;

    if (typeof date === "string") {
      d = new Date(date);
    } else {
      d = date;
    }

    const timezone = d.toString().match(/([A-Z]+[+-][0-9]+.*)/)![1];
    
    const locale = locales[navigator.language];
    
    // const zonedDate = zonedTimeToUtc(d,  timezone);
    const pattern = "yyyy-MM-dd";
    
    return format(d, pattern, { locale });
  }
  return null;
};
