import AppApiBase from "@/services/api/ApiBase";
import { AppConfig } from "@/services/config/AppConfig";

import { IAppApi } from "./IAppApi";
import {
  IAuthRequest,
  IAuthResponse,
  IUserProfile,
  IAdPlan,
  IAdCost,
  IAdRequest,
  IAdvertiser,
  ICreateUserAccount,
  IPasswordResetRequest,
  IUpdateUserAccount,
  IUserAccount,
  IEdition,
  IAdBookingView,
  IAdBooking,
  IAdFormat,
  IContent,
  IContentView,
  IEditionOverview,
  IPhotoCompetitionContribution,
} from "./models";

export class AppApi extends AppApiBase implements IAppApi {
  public constructor() {
    super(AppConfig.ApiUrl);
  }

  login = async (request: IAuthRequest): Promise<IAuthResponse> =>
    this.post<IAuthRequest, IAuthResponse>("auth/login", request);

  logout = async (): Promise<boolean> =>
    this.post<null, boolean>("auth/logout", null);

  refresh = async (): Promise<IAuthResponse> =>
    this.post<null, IAuthResponse>("auth/refresh", null);

  getUserProfile = async (): Promise<IUserProfile> =>
    this.get<IUserProfile>("UserProfile");

  // Advertisements
  getAdvertisementPlans = async (): Promise<IAdPlan[]> =>
    this.get<IAdPlan[]>("Advertisement/Info");

  getAdvertisementCosts = async (): Promise<IAdCost[]> =>
    this.get<IAdCost[]>("Advertisement/Costs");

  sendAdvertismentRequest = async (request: IAdRequest): Promise<IAdRequest> =>
    this.post<IAdRequest, IAdRequest>("Advertisement/Request", request);

  // Admin - User Management
  getUserAccounts = async (): Promise<IUserAccount[]> =>
    this.get<IUserAccount[]>("Account");

  getRoles = async (): Promise<string[]> => this.get<string[]>("Account/roles");

  createAccount = async (account: ICreateUserAccount): Promise<IUserAccount> =>
    this.post<ICreateUserAccount, IUserAccount>("Account", account);

  resetAccountPassword = async (
    request: IPasswordResetRequest
  ): Promise<void> =>
    this.post<IPasswordResetRequest, void>("Account/passwordreset", request);

  getUserAccount = async (userId: string): Promise<IUserAccount> =>
    this.get(`Account/${userId}`);

  updateUserAccount = async (
    userId: string,
    account: IUpdateUserAccount
  ): Promise<IUserAccount> =>
    this.post<IUpdateUserAccount, IUserAccount>(`Account/${userId}`, account);

  // Admin - Advertisement
  getAdvertisers = async (): Promise<IAdvertiser[]> =>
    this.get("advertisement/advertisers");

  getAdvertiser = async (advertiserId: string): Promise<IAdvertiser> =>
    this.get(`advertisement/advertiser/${advertiserId}`);

  saveAdvertiser = async (advertiser: IAdvertiser): Promise<IAdvertiser> =>
    this.put("advertisement/advertiser", advertiser);

  createAdvertiser = async (advertiser: IAdvertiser): Promise<IAdvertiser> =>
    this.post("advertisement/advertiser", advertiser);

  deleteAdvertiser = async (advertiserId: string): Promise<void> =>
    this.delete(`advertisement/advertiser?advertiserId=${advertiserId}`);

  // Admin - AdFormats
  getAdFormats = async (): Promise<IAdFormat[]> =>
    this.get("advertisement/formats");

  // Admin - AdBooking
  getAdBookings = async (): Promise<IAdBookingView[]> =>
    this.get("advertisement/booking/list");

  getAdBooking = async (adBookingId: string): Promise<IAdBooking> =>
    this.get(`advertisement/booking/${adBookingId}`);

  createAdBooking = async (adBooking: IAdBooking): Promise<IAdBooking> =>
    this.post("advertisement/booking", adBooking);

  saveAdBooking = async (adBooking: IAdBooking): Promise<IAdBooking> =>
    this.put("advertisement/booking", adBooking);

  deleteAdBooking = async (adBookingId: string): Promise<void> =>
    this.delete(`advertisement/booking/${adBookingId}`);

  exportAdBookingsToExcel = async (editionId: string): Promise<Blob> =>
    this.getBlob(`advertisement/booking/export/${editionId}`);

    
  // Admin - Editions
  getEditions = async (): Promise<IEdition[]> => this.get("edition");

  getEdition = async (editionId: string): Promise<IEdition> =>
    this.get(`edition/${editionId}`);

  createEdition = async (edition: IEdition): Promise<IEdition> =>
    this.post("edition", edition);

  saveEdition = async (edition: IEdition): Promise<IEdition> =>
    this.put("edition", edition);

  deleteEdition = async (editionId: string): Promise<void> =>
    this.delete(`edition/${editionId}`);

  getEditionOverview = async (editionId: string): Promise<IEditionOverview> =>
    this.get(`edition/overview/${editionId}`);


  // Admin - Content
  getContents = async (): Promise<IContentView[]> =>
    this.get("EditorialContent/list");

  getContent = async (contentId: string): Promise<IContent> =>
    this.get(`EditorialContent/${contentId}`);

  addContent = async (content: IContent): Promise<IContent> =>
    this.post("EditorialContent", content);

  saveContent = async (content: IContent): Promise<IContent> =>
    this.put("EditorialContent", content);

  deleteContent = async (contentId: string): Promise<void> =>
    this.delete(`EditorialContent/${contentId}`);

  // Photo competition

  photoCompCompetitorExists = async (
    contribution: IPhotoCompetitionContribution
  ): Promise<boolean> =>
    this.post("PhotoCompetition/hascontributed", contribution);

  photoCompValidateCode = async (code: string): Promise<boolean> =>
    this.post(`PhotoCompetition/validatecode/${code}`, null);

  photoCompAddContribution = async (
    data: IPhotoCompetitionContribution
  ): Promise<string> => this.post("PhotoCompetition", data);

  photoCompAddImage = async (data: FormData, progresshandler: any): Promise<void> =>
    this.post("PhotoCompetition/upload", data, progresshandler);


  // Tools
  scanOcr = async (data: FormData): Promise<any> =>
    this.post("Cognitive/ocr", data);

  speechToText = async (data: FormData): Promise<any> =>
    this.post("Cognitive/speechtotext", data);

}

export const api: IAppApi = new AppApi();
