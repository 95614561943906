
export const sortArray = (array: string[]): string[] => {
    return array.sort((n1, n2) => {
        if (n1 > n2) {
            return 1;
        }

        if (n1 < n2) {
            return -1;
        }

        return 0;
    });
}

export const sortArrayDesc = (array: string[]): string[] => {
    return array.sort((n1, n2) => {
        if (n1 < n2) {
            return 1;
        }

        if (n1 > n2) {
            return -1;
        }

        return 0;
    });
}