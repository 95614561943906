
// import { Vue } from "vue-class-component";
import { defineComponent, computed } from "vue";
import { AppConfig, api } from "@/services";

export default defineComponent({
  setup() {
    // const uiVersion = computed(() => AppConfig.AppVersion);
    // const env = computed(() => process.env.NODE_ENV);
    // const apiVersion = 
    // return {
    //   uiVersion

    // }
  }
});

// export default class Home extends Vue {
//   // private appApi = AppApi();

//   private uiVersion: string = AppConfig.AppVersion;

//   private env: string = process.env.NODE_ENV;

//   private apiVersion: string = "N/A";

//   async mounted() {
//     // this.apiVersion = await api.getApiVersion();
//   }
// }
