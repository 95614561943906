import {
  VuexModule, Module, MutationAction, Mutation, Action, getModule
} from "vuex-module-decorators";
import { rootstore } from "@/stores";

@Module({
  name: "message",
  store: rootstore,
  dynamic: true
})
class MessageModule extends VuexModule {
  currentmessage = "none";

  get message(): string {
    return this.currentmessage;
  }

  @Mutation
  private setCurrentMessage(message: string): void {
    this.currentmessage = message;
  }

  @Action
  setMessage(message: string): void {
    this.context.commit("setCurrentMessage", message);

    setTimeout(() => {
      this.context.commit("setCurrentMessage", "HA!");
    }, 1000);
  }
}

export default getModule(MessageModule);
