import { HttpClient } from "@/services/http";
import { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosRequestHeaders } from "axios";
export default abstract class AppApiBase extends HttpClient {
  public constructor(baseUrl: string) {
    super(baseUrl);
    this.initializeRequestInterceptor();
    this.initializeResoponseInterceptor();
  }

  private bearerToken = "";

  private handleResponse = (axiosResponse: AxiosResponse): any =>
    axiosResponse.data;

  private handleError = (axiosError: AxiosError): string => {
    console.log("axiosError: ", axiosError);
    console.log("axiosError: ", axiosError.response);

    if (axiosError.response && (axiosError.response.data as string).length > 0) {
      throw new Error(axiosError.response.data as string);
    }
    throw new Error(axiosError.message);
  };

  private initializeResoponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  };

  private handleApiRequest = async (config: AxiosRequestConfig) => {

    console.log(this.bearerToken);

    if (this.bearerToken !== "" || this.bearerToken !== undefined) {
      config.headers!['Authorization'] = `Bearer ${this.bearerToken}`;
    }
    return config;
  };

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleApiRequest);
  };

  public setBearer = (bearerToken: string): void => {
    this.bearerToken = bearerToken;
  };

  public clearBearer = (): void => {
    this.bearerToken = "";
  };

  public hasBearer = (): boolean => this.bearerToken !== "";
}
