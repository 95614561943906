import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90e8ebaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = ["id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: _ctx.field,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    (!_ctx.readonly)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          type: "text",
          class: "form-control form-control-sm",
          id: _ctx.field,
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
        }, null, 40, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.readonly)
      ? (_openBlock(), _createElementBlock("input", {
          key: 1,
          type: "text",
          disabled: "disabled",
          class: "form-control form-control-sm",
          id: _ctx.field,
          value: _ctx.readonly
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}