
import router from "@/router";
import { api, IAdvertiser } from "@/services/api";
import { TextField, FieldErrors } from "@/components";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { IsEmail, IsNotEmpty } from "class-validator";
import { getEmptyGuid, objectValidator } from "@/utils";

class Form implements IAdvertiser {
  advertiserId = "";

  @IsNotEmpty({ message: "Saknas" })
  company = "";

  organizationalNumber = "";

  @IsNotEmpty({ message: "Saknas" })
  address = "";

  @IsNotEmpty({ message: "Saknas" })
  zipCode = "";

  @IsNotEmpty({ message: "Saknas" })
  city = "";

  @IsNotEmpty({ message: "Saknas" })
  contact = "";

  phone = "";

  @IsNotEmpty({ message: "Saknas" })
  @IsEmail(undefined, { message: "Inte en e-post adress" })
  email = "";

  alternativeContact = "";

  comments = "";
}

interface State {
  form: Form;
  errors: Record<string, any>;
}

export default defineComponent({
  components: {
    TextField,
    FieldErrors,
  },
  setup() {
    const state = reactive<State>({
      form: new Form(),
      errors: {},
    });

    const hasErrors = computed(() => Object.keys(state.errors).length !== 0);

    const isNew = computed(() => state.form.advertiserId === getEmptyGuid());

    const validate = async () => {
      state.errors = await objectValidator(state.form);
    };

    const getAdvertiser = async (advertiserId: string) => {
      const advertiser = await api.getAdvertiser(advertiserId);

      state.form.advertiserId = advertiser.advertiserId;
      state.form.company = advertiser.company;
      state.form.organizationalNumber = advertiser.organizationalNumber;
      state.form.address = advertiser.address;
      state.form.zipCode = advertiser.zipCode;
      state.form.city = advertiser.city;
      state.form.contact = advertiser.contact;
      state.form.email = advertiser.email;
      state.form.phone = advertiser.phone;
      state.form.alternativeContact = advertiser.alternativeContact;
      state.form.comments = advertiser.comments;
    };

    onMounted(async () => {
      if (
        // eslint-disable-next-line operator-linebreak
        router.currentRoute.value.params &&
        router.currentRoute.value.params.advertiserId
      ) {
        await getAdvertiser(
          router.currentRoute.value.params.advertiserId as string
        );
      } else {
        state.form.advertiserId = getEmptyGuid();
      }
      await validate();
    });

    const cancel = () => router.push({ name: "advertisers" });

    const save = async () => {
      if (hasErrors.value) return;
      if (isNew.value) {
        await api.createAdvertiser(state.form);
      } else {
        await api.saveAdvertiser(state.form);
      }
      router.push({ name: "advertisers" });
    };

    return {
      state,
      hasErrors,
      validate,
      cancel,
      save,
      isNew,
    };
  },
});
