
import router from "@/router";
import {
  api,
  IAdBooking,
  AdBookingStatusEnum,
  IAdFormat,
  IEdition,
  IAdvertiser,
  IContent,
  ContentStatusEnum,
  IEnumItem,
  GetContentStatuses,
} from "@/services/api";
import { TextField, FieldErrors, NumberField } from "@/components";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  watch,
} from "vue";
import { IsEmail, IsNotEmpty, Min } from "class-validator";
import { getEmptyGuid, objectValidator } from "@/utils";

class Form implements IContent {
  contentId = "";

  @IsNotEmpty({ message: "Saknas" })
  title = "";

  @IsNotEmpty({ message: "Saknas" })
  responsible = "";

  status = ContentStatusEnum.New;

  @IsNotEmpty({ message: "Saknas" })
  editionId = "";

  comments = "";

  @IsNotEmpty({ message: "Saknas" })
  @Min(0, { message: "Måste vara 0 eller mer" })
  contentSize = 1;
}

interface State {
  form: Form;
  errors: Record<string, any>;
  editions: IEdition[];
  statuses: IEnumItem<number>[];
}

export default defineComponent({
  components: {
    TextField,
    NumberField,
    FieldErrors,
  },
  setup() {
    const state = reactive<State>({
      form: new Form(),
      errors: {},
      editions: [],
      statuses: GetContentStatuses(),
    });

    const hasErrors = computed(() => Object.keys(state.errors).length !== 0);

    const isNew = computed(() => state.form.contentId === getEmptyGuid());

    const validate = async () => {
      state.errors = await objectValidator(state.form);
    };

    const getContent = async (contentId: string) => {
      const content = await api.getContent(contentId);
      state.form.contentId = content.contentId;
      state.form.title = content.title;
      state.form.responsible = content.responsible;
      state.form.status = content.status;
      state.form.editionId = content.editionId;
      state.form.comments = content.comments;
      state.form.contentSize = content.contentSize;
    };

    onBeforeMount(async () => {
      state.editions = await api.getEditions();
    });

    onMounted(async () => {
      if (
        // eslint-disable-next-line operator-linebreak
        router.currentRoute.value.params &&
        router.currentRoute.value.params.contentId
      ) {
        await getContent(router.currentRoute.value.params.contentId as string);
      } else {
        state.form.contentId = getEmptyGuid();
      }
      await validate();
    });

    const cancel = () => router.push({ name: "contents" });

    const save = async () => {
      if (hasErrors.value) return;
      if (isNew.value) {
        await api.addContent(state.form);
      } else {
        await api.saveContent(state.form);
      }
      router.push({ name: "contents" });
    };

    return {
      state,
      hasErrors,
      validate,
      cancel,
      save,
      isNew,
    };
  },
});
