
import { IAuthRequest } from "@/services";
import { authstore, profilestore } from "@/stores";
import { computed, defineComponent, reactive } from "vue";
import { objectValidator } from "@/utils";
import { IsNotEmpty, IsEmail } from "class-validator";

class FormData implements IAuthRequest {
  @IsEmail(undefined, { message: "Inte en giltig e-postadress" })
  email = "";

  @IsNotEmpty({ message: "Lösenord måste anges" })
  password = "";
}

interface State {
  form: FormData;
  formErrors: Record<string, any>;
  isAuthed: boolean;
  roles: string[];
}

export default defineComponent({
  props: {
    title: { type: String, default: "Inloggning" },
  },
  setup() {
    const state = reactive<State>({
      form: new FormData(),
      formErrors: [],
      isAuthed: false,
      roles: [],
    });

    const validateForm = async (): Promise<boolean> => {
      state.formErrors = await objectValidator(state.form);
      return new Promise<boolean>((res) => {
        res(Object.keys(state.formErrors).length === 0);
      });
    };

    const submit = async (e: Event) => {
      e.preventDefault();
      const isValid = await validateForm();
      if (isValid) {
        authstore.authenticateUser({
          email: state.form.email,
          password: state.form.password,
        });
      }
    };

    const isAuthed = computed(() => authstore.isAuthenticated);
    const roles = computed(() => profilestore.roles);

    const logout = () => authstore.logout();

    const error = computed(() => authstore.error);

    return {
      state,
      validateForm,
      submit,
      isAuthed,
      roles,
      logout,
      error,
    };
  },
});
