
/* eslint-disable no-use-before-define */
import router from "@/router";
import { api, IEdition } from "@/services/api";
import { editionstore } from "@/stores";
import {
  editionSorter,
  getEmptyGuid,
  IsLaterThan,
  objectValidator,
  toUtcDate,
} from "@/utils";
import {
  IsDate,
  IsInt,
  IsNotEmpty,
  Max,
  Min,
  MinDate,
  ValidateIf,
} from "class-validator";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { FieldErrors, TextField, DateField } from "@/components";

class Form implements IEdition {
  editionId = "";

  @ValidateIf((o: Form) => o.editionId === getEmptyGuid())
  @IsNotEmpty({ message: "Årtal saknas" })
  @Min(new Date().getFullYear(), {
    message: `Måste vara minst ${new Date().getFullYear()}`,
  })
  year = new Date().getFullYear();

  @IsInt({ message: "Måste vara ett nummer" })
  @Min(1, { message: "Måste vara minst 1" })
  @Max(4, { message: "Får vara max 4" })
  edition = 1;

  @IsDate()
  @ValidateIf((o: Form) => o.editionId === getEmptyGuid())
  @MinDate(new Date(Date.now()), { message: "Inte tidigare än dagens datum" })
  scriptDeadline = new Date();

  @IsDate({ message: "Fel datumformat" })
  @IsLaterThan("scriptDeadline", { message: "Måste vara efter manusstopp" })
  compotitionDate = new Date();

  @IsDate({ message: "Fel datumformat" })
  @IsLaterThan("compotitionDate", { message: "Måste vara efter sättning" })
  printDate = new Date();

  @IsDate({ message: "Fel datumformat" })
  @IsLaterThan("printDate", { message: "Måste vara efter tryck" })
  publicationDate = new Date();

  editionName = "";
}

interface State {
  form: Form | null;
  errors: Record<string, any>;
}

export default defineComponent({
  components: {
    FieldErrors,
    TextField,
    DateField,
  },
  setup() {
    const state = reactive<State>({
      form: null,
      errors: {},
    });

    const hasErrors = computed(() => Object.keys(state.errors).length !== 0);

    const isNew = computed(() => state.form?.editionId === getEmptyGuid());

    const validate = async () => {
      state.errors = await objectValidator(state.form);
    };

    const getEdition = async (editionId: string) => {
      const edition = await editionstore.getEdition(editionId);
      state.form = new Form();
      state.form.editionId = edition.editionId;
      state.form.year = edition.year;
      state.form.edition = edition.edition;
      state.form.scriptDeadline = new Date(edition.scriptDeadline);
      state.form.compotitionDate = new Date(edition.compotitionDate);
      state.form.printDate = new Date(edition.printDate);
      state.form.publicationDate = new Date(edition.publicationDate);
    };

    onMounted(async () => {
      if (
        // eslint-disable-next-line operator-linebreak
        router.currentRoute.value.params &&
        router.currentRoute.value.params.editionId
      ) {
        await getEdition(router.currentRoute.value.params.editionId as string);
      } else {
        state.form = new Form();
        state.form.editionId = getEmptyGuid();
      }
      await validate();
    });

    const cancel = () => router.push({ name: "editions" });

    const save = async () => {
      if (state.form) {
        await editionstore.saveEdition(state.form);
        router.push({ name: "editions" });
      }
    };

    return { state, validate, cancel, save, isNew, hasErrors };
  },
});
