<template>
  <div class="">
    <h3>Tipsa redaktionen</h3>
    <p>
      Redaktionen för Nationellt Pistolskytte tar mer än gärna emot tips på
      olika reportage och artiklar.
    </p>
    <p>
      Kontakta oss på
      <a href="mailto:np@liteolika.se?subject=Tips">np@liteolika.se</a>
    </p>
  </div>
</template>
