import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  Router,
  RouteLocationNormalized,
  NavigationGuardNext,
} from "vue-router";

import { profilestore, authstore, awaitStateInit } from "@/stores";

// Layouts
import DefaultLayout from "@/views/layouts/Default.vue";
import EmptyLayout from "@/views/layouts/Empty.vue";

// Shared
import NotFoundView from "@/views/404.vue";

// Views
import TipsView from "@/views/Tips.vue";
import WriteView from "@/views/Write.vue";
import AdvertisementView from "@/views/advertisement/Advertisement.vue";
import Landing from "@/views/Landing.vue";
import ProductionPlan from "@/views/advertisement/Plan.vue";
import AdCosts from "@/views/advertisement/Costs.vue";
import AdBookingRequest from "@/views/advertisement/Request.vue";

import PhotoComp2021 from "@/views/events/PhotoComp2021.vue";

import LoginView from "@/views/account/Login.vue";
import UnauthorizedView from "@/views/Unauthorized.vue";

// Admin - Users
import AccountsView from "@/views/management/UserAccounts/Accounts.vue";
import EditAccountView from "@/views/management/UserAccounts/EditAccount.vue";
import CreateAccountView from "@/views/management/UserAccounts/CreateAccount.vue";

// Admin - Advertisers
import AdvertisersView from "@/views/management/Advertising/Advertisers.vue";
import EditAdvertiserView from "@/views/management/Advertising/EditAdvertiser.vue";

// Admin - AdBookings
import AdBookingsView from "@/views/management/Advertising/Adbookings.vue";
import EditAdBookingView from "@/views/management/Advertising/EditAdBooking.vue";

// Admin - Editions
import EditionsView from "@/views/management/Editions/Editions.vue";
import EditionEditor from "@/views/management/Editions/EditionEditor.vue";

// Admin - Content
import Contents from "@/views/management/Content/Contents.vue";
import ContentEditor from "@/views/management/Content/EditContent.vue";
import ContentOverview from "@/views/management/Content/Overview.vue";

import PublicRoutes from "./public-routes";
import AdminRoutes from "./admin-routes";
import ManagementRoutes from "./management-routes";
import AccountRoutes from "./account-routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/",
    name: "",
    component: DefaultLayout,
    children: PublicRoutes,
  },
  {
    path: "/account",
    component: DefaultLayout,
    children: AccountRoutes,
  },
  {
    path: "/admin",
    name: "",
    meta: { secure: true },
    component: DefaultLayout,
    children: AdminRoutes,
  },
  {
    path: "/mgmt",
    name: "",
    component: DefaultLayout,
    meta: { secure: true },
    children: ManagementRoutes,
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundView,
  },
];

const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const stateGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  await awaitStateInit();
  next();
};

const hasRoles = (roles: string[]): boolean =>
  profilestore.roles.some(
    (userRole: string) => roles.indexOf(userRole.toLowerCase()) !== -1
  );

const authGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.meta.secure === true && authstore.isAuthenticated === false) {
    next({ path: "/account/login", query: { redirect: to.fullPath } });
  } else {
    if (to.meta.roles !== undefined && !hasRoles(to.meta.roles as string[])) {
      next({ path: "/account/unauthorized" });
    }
    next();
  }
  next();
};

router.beforeEach(stateGuard);
router.beforeEach(authGuard);

export default router;
