import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store, { storagekey } from "@/stores/rootstore";

import { api, IAdCost, IAdPlan } from "@/services/api";
import { editionSorter, getEmptyGuid } from "@/utils";

@Module({
  name: "ads",
  store,
  dynamic: true,
  namespaced: true,
})
class AdStore extends VuexModule {
  private adPlans: IAdPlan[] = [];

  private adCosts: IAdCost[] = [];

  get plans(): IAdPlan[] {
    return this.adPlans;
  }

  get costs(): IAdCost[] {
    return this.adCosts;
  }

  @Mutation
  private setAdplans(plans: IAdPlan[]) {
    this.adPlans = plans;
  }

  @Mutation
  private setAdCosts(costs: IAdCost[]) {
    this.adCosts = costs;
  }

  @Action
  async init() {
    const costs = await api.getAdvertisementCosts();
    const plans = await api.getAdvertisementPlans();

    this.context.commit("setAdplans", plans);
    this.context.commit("setAdCosts", costs);
  }
}

export default getModule(AdStore);
