import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store, { storagekey } from "@/stores/rootstore";

import { api, IUserProfile } from "@/services/api";

@Module({
  name: "profile",
  store,
  dynamic: true,
  namespaced: true,
  preserveState: localStorage.getItem(storagekey) !== null,
})
class ProfileStore extends VuexModule {
  private profile: IUserProfile | null = null;

  private appMenuVisible = true;

  get roles(): string[] {
    return this.profile === null ? [] : this.profile.roles;
  }

  get username(): string {
    return this.profile === null ? "" : this.profile.userId;
  }

  get fullname(): string {
    return this.profile === null ? "" : this.profile.fullname;
  }

  get showAppMenu(): boolean {
    return this.appMenuVisible;
  }

  @Mutation
  private setProfile(profile: IUserProfile) {
    this.profile = profile;
  }

  @Mutation
  private clearProfile() {
    this.profile = null;
  }

  @Mutation
  private setAppMenuVisibility(show: boolean) {
    this.appMenuVisible = show;
  }

  @Action
  async fetchProfile() {
    const profile = await api.getUserProfile();
    this.context.commit("setProfile", profile);
  }

  @Action
  async disposeProfile() {
    this.context.commit("clearProfile");
  }

  @Action
  async toggleAppMenu() {
    this.context.commit("setAppMenuVisibility", !this.showAppMenu);
  }
}

export default getModule(ProfileStore);
