/* eslint-disable no-restricted-syntax */
import { validate, ValidationError } from "class-validator";

// tslint:disable-next-line:typedef
export const getValidationErrors = (errors: ValidationError[]): Record<string, any> => {
  const propBag: any = {};

  for (const error of errors) {
    for (const key in error.constraints) {
      if (Object.prototype.hasOwnProperty.call(error.constraints, key)) {
        (propBag as any)[error.property] = error.constraints;
      }
    }
  }
  return propBag;
};

// tslint:disable-next-line:typedef
export const objectValidator = async (data: any): Promise<Record<string, any>> => {
  const errors: ValidationError[] = await validate(data);
  return new Promise<Record<string, any>>((res) => {
    res(getValidationErrors(errors));
  });
};
