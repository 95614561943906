
// eslint-disable-next-line object-curly-newline
import { computed, defineComponent, onMounted, reactive } from "vue";
import { TopNav, AppNav } from "@/components";
import { authstore, profilestore } from "@/stores";

export default defineComponent({
  components: { TopNav, AppNav },
  setup() {
    const state = reactive({
      show: true,
      mainClasses: "col-md-12",
      ready: false,
    });

    const showAppMenu = computed(
      () => authstore.isAuthenticated === true && profilestore.showAppMenu === true
    );
    // eslint-disable-next-line no-confusing-arrow
    const mainClasses = computed<string>(
      () => (showAppMenu.value === true ? "col-md-9 col-lg-10" : "col-md-12")
      // eslint-disable-next-line function-paren-newline
    );

    const toggleAppMenu = async () => {
      state.show = !state.show;
    };

    onMounted(async () => {
      await authstore.refresh();
      state.ready = true;
    });

    return {
      state,
      toggleAppMenu,
      showAppMenu,
      mainClasses,
    };
  },
});
