
import router from "@/router";
import { api, IContentView } from "@/services/api";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { SorterDirection, MultiFilterSelector } from "@/components";

interface IFilters {
  editionName: string[];
  status: string[];
  responsible: string[];
  textfilter: string;
}

// interface IState {
//   contents: IContentView[];
// }

export default defineComponent({
  components: {
    MultiFilterSelector,
    SorterDirection,
  },
  setup() {
    // const state = reactive<IState>({
    //   contents: [],
    // });

    const filters = reactive<IFilters>({
      editionName: [],
      status: [],
      responsible: [],
      textfilter: "",
    });

    const sorting = reactive({
      field: "",
      isDesc: false,
    });

    const contents = reactive<IContentView[]>([]);

    const filteredContent = computed(() => {
      let filtered = contents;

      if (filters.textfilter.length > 0) {
        filtered = filtered.filter(
          (f) =>
            f.title
              .toLowerCase()
              .trim()
              // eslint-disable-next-line operator-linebreak
              .includes(filters.textfilter.toLowerCase().trim()) ||
            f.responsible
              .toLowerCase()
              .trim()
              .includes(filters.textfilter.toLowerCase().trim())
        );
      }

      if (filters.editionName.length > 0) {
        filtered = filtered.filter(
          (f) => filters.editionName.indexOf(f.editionName) !== -1
        );
      }

      if (filters.status.length > 0) {
        filtered = filtered.filter(
          (f) => filters.status.indexOf(f.status) !== -1
        );
      }

      if (filters.responsible.length > 0) {
        filtered = filtered.filter(
          (f) => filters.responsible.indexOf(f.responsible) !== -1
        );
      }

      if (sorting.field === "asc") {
        return filtered;
      }

      if (sorting.field !== "") {
        return filtered.sort((a: any, b: any) => {
          if (sorting.isDesc) {
            if (a[sorting.field] < b[sorting.field]) return -1;
            return 1;
          }
          if (a[sorting.field] > b[sorting.field]) return -1;
          return 1;
        });
      }
      return filtered;
    });

    const loadContents = async () => {
      const items = await api.getContents();
      contents.slice(0, contents.length);
      contents.push(...items);
    };

    onMounted(async () => {
      await loadContents();
    });

    const editContent = async (contentId: string) => {
      router.push({ name: "editcontent", params: { contentId } });
    };

    const deleteContent = async (content: IContentView) => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Vill du verkligen ta bort innehållet '${content.title}'?`)) {
        await api.deleteContent(content.contentId);
        await loadContents();
      }
    };

    const clearFilters = (field: string) => {
      const fs = filters as any;
      if (fs[field] && fs[field].length > 0) {
        fs[field].splice(0, fs[field].length);
      }
    };

    const setFilters = (field: string, values: string[]) => {
      const fs = filters as any;
      fs[field].push(...values);
    };

    const saveFilters = () => {
      localStorage.setItem("filters.contents", JSON.stringify(filters));
    };

    const filterApplied = (values: string[], field: string | undefined) => {
      if (field !== undefined) {
        clearFilters(field);
        setFilters(field, values);
        saveFilters();
      }
    };

    const loadFilters = () => {
      const savedFiltersJson = localStorage.getItem("filters.contents");
      if (savedFiltersJson !== null) {
        const savedFilters = JSON.parse(savedFiltersJson) as IFilters;
        clearFilters("editionName");
        setFilters("editionName", savedFilters.editionName);

        clearFilters("status");
        setFilters("status", savedFilters.status);

        clearFilters("responsible");
        setFilters("responsible", savedFilters.responsible);
      }
    };

    const sortOrderApplied = (desc: boolean, field: string) => {
      sorting.field = field;
      sorting.isDesc = desc;
    };

    loadFilters();

    return {
      contents,
      filteredContent,
      editContent,
      deleteContent,
      sorting,
      filterApplied,
      sortOrderApplied,
      filters,
    };
  },
});
