
import { defineComponent, reactive } from "vue";
import { IAdCost } from "@/services/api";
import { loadspinner } from "@/components";
import adstore from "@/stores/modules/adstore";
import AdNav from "./AdNav.vue";

interface State {
  costs: IAdCost[];
  loading: boolean;
}

export default defineComponent({
  components: { loadspinner, AdNav },
  setup() {
    const state = reactive<State>({
      costs: adstore.costs,
      loading: false,
    });

    return {
      state,
    };
  },
});
