import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row mt-3 mb-3" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row mt-3 mb-3" }
const _hoisted_5 = { class: "col-md-3" }
const _hoisted_6 = { class: "col-md-3" }
const _hoisted_7 = { class: "row mt-3 mb-3" }
const _hoisted_8 = { class: "col-md-3" }
const _hoisted_9 = { class: "col-md-3" }
const _hoisted_10 = { class: "row mt-3 mb-3" }
const _hoisted_11 = { class: "col-md-3" }
const _hoisted_12 = { class: "col-md-3" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_field = _resolveComponent("text-field")!
  const _component_field_errors = _resolveComponent("field-errors")!
  const _component_date_field = _resolveComponent("date-field")!

  return (_ctx.state.form)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.isNew ? "Skapa utgåva" : "Ändra utgåva"), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_text_field, {
                  label: "Årtal",
                  modelValue: _ctx.state.form.year,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.form.year) = $event)),
                  modelModifiers: { number: true },
                  onInput: _ctx.validate
                }, null, 8, ["modelValue", "onInput"]),
                _createVNode(_component_field_errors, {
                  errors: _ctx.state.errors,
                  single: "true",
                  field: "year"
                }, null, 8, ["errors"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_text_field, {
                  label: "Utgåva",
                  modelValue: _ctx.state.form.edition,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.form.edition) = $event)),
                  modelModifiers: { number: true },
                  onInput: _ctx.validate
                }, null, 8, ["modelValue", "onInput"]),
                _createVNode(_component_field_errors, {
                  errors: _ctx.state.errors,
                  single: "true",
                  field: "edition"
                }, null, 8, ["errors"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_date_field, {
                  modelValue: _ctx.state.form.scriptDeadline,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.form.scriptDeadline) = $event)),
                  label: "Manusstopp",
                  onSelected: _ctx.validate,
                  startingView: "year"
                }, null, 8, ["modelValue", "onSelected"]),
                _createVNode(_component_field_errors, {
                  errors: _ctx.state.errors,
                  single: "true",
                  field: "scriptDeadline"
                }, null, 8, ["errors"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_date_field, {
                  modelValue: _ctx.state.form.compotitionDate,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.form.compotitionDate) = $event)),
                  label: "Sättning",
                  onSelected: _ctx.validate,
                  startingView: "year"
                }, null, 8, ["modelValue", "onSelected"]),
                _createVNode(_component_field_errors, {
                  errors: _ctx.state.errors,
                  single: "true",
                  field: "compotitionDate"
                }, null, 8, ["errors"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_date_field, {
                  modelValue: _ctx.state.form.printDate,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.form.printDate) = $event)),
                  label: "Tryck",
                  onSelected: _ctx.validate,
                  startingView: "year"
                }, null, 8, ["modelValue", "onSelected"]),
                _createVNode(_component_field_errors, {
                  errors: _ctx.state.errors,
                  single: "true",
                  field: "printDate"
                }, null, 8, ["errors"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_date_field, {
                  modelValue: _ctx.state.form.publicationDate,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.form.publicationDate) = $event)),
                  label: "Utgivning",
                  onSelected: _ctx.validate,
                  startingView: "year"
                }, null, 8, ["modelValue", "onSelected"]),
                _createVNode(_component_field_errors, {
                  errors: _ctx.state.errors,
                  single: "true",
                  field: "publicationDate"
                }, null, 8, ["errors"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary btn-sm me-1",
              disabled: _ctx.hasErrors,
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
            }, " Spara ", 8, _hoisted_15),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary btn-sm",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
            }, " Avbryt ")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}