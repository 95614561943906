import { IEdition } from "@/services/api/models";

export const editionSorter = (desc: boolean) => (
  a: IEdition,
  b: IEdition
): number => {
  const numa = parseInt(`${a.year}${a.edition}`, 10);
  const numb = parseInt(`${b.year}${b.edition}`, 10);
  return desc ? numa - numb : numb - numa;
};
