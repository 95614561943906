import { createApp } from 'vue'
import { FontAwesomeIcon } from "@/plugins/font-awesome";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { rootstore } from "./stores";

import "@/styles/custom.scss";

import init from "./initializer";

init().then(() => {
    createApp(App)
      .use(rootstore)
      .use(router)
      .component("fa", FontAwesomeIcon)
      .mount("#app");
  });
