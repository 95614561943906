
import { sortArray } from "@/utils";
import { defineComponent, PropType, reactive, watch, computed } from "vue";

interface State {
  items: any[];
  checkedItems: any[];
  preSelected: any[];
}

export default defineComponent({
  name: "MultiSelect",
  inheritAttrs: false,
  emits: ["changed"],
  props: {
    items: {
      type: Object as PropType<any[]>,
      required: true,
    },
    field: {
      type: String,
      required: false,
    },
    selected: {
      type: Object as PropType<any[]>,
      default: () => Object,
    },
  },
  setup(props, context) {
    const state = reactive<State>({
      items: [],
      checkedItems: [],
      preSelected: props.selected as any[],
    });

    const check = () => {
      context.emit("changed", state.checkedItems);
    };

    const hasItems = computed(() => state.checkedItems.length > 0);

    const setItems = (values: any[]) => {
      let vals: string[] = [];

      values.forEach((i) => {
        if (props.field !== undefined) {
          vals.push(i[props.field]);
        } else {
          vals.push(i);
        }
      });

      vals = vals.filter((value, index, self) => self.indexOf(value) === index);

      state.items = sortArray(vals);

    };

    watch(props.items, (values: any[]) => {
      setItems(values);
    });

    watch(props.selected, (values: any[]) => {
      state.checkedItems = values;
    });

    if (state.preSelected && state.preSelected.length > 0) {
      state.checkedItems.push(...state.preSelected);
    }

    setItems(props.items);

    const onSelectionChanged = () => {
      context.emit("changed", state.checkedItems, props.field);
    };

    return {
      state,
      check,
      hasItems,
      onSelectionChanged,
    };
  },
});
