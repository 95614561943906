
import { computed, defineComponent, onMounted, reactive } from "vue";
import { TextField, FieldErrors, ImageUpload } from "@/components"; // @ is an alias to /src
import { api } from "@/services/api";
import { IsEmail, IsNotEmpty, Matches } from "class-validator";
import { getNewGuid, IsNotNull, objectValidator } from "@/utils";

class ImageForm {
  @IsNotNull({ message: "Saknas" })
  image: File | null = null;

  @IsNotEmpty({ message: "Saknas" })
  description = "";

  fileName = "";

  id = getNewGuid();

  errors: Record<string, any> = [];
}

interface State {
  form: ImageForm;
  sent: boolean;
  submitInProgress: boolean;
  submitProgress: number;
  submitMessage: string;
  submitError: string;
  result: string;
}

export default defineComponent({
  components: {
    TextField,
    FieldErrors,
    ImageUpload,
  },
  setup() {
    const state = reactive<State>({
      submitProgress: 0,
      submitInProgress: false,
      form: new ImageForm(),
      sent: false,
      submitMessage: "",
      submitError: "",
      result: "",
    });

    const hasErrors = computed(() => {
      let errorish = false;
      if (Object.keys(state.form.errors).length !== 0) {
        errorish = true;
      }
      return errorish;
    });

    const validateForm = async () => {
      state.form.errors = await objectValidator(state.form);
    };

    onMounted(async () => {
      await validateForm();
    });

    const imageAdded = async (files: File[], pictureId: string) => {
      state.form.image = files[0];
      state.form.errors = await objectValidator(state.form.image);
    };

    const scan = () => {
      const data = new FormData();
      data.append("Image", state.form.image as Blob);

      state.submitInProgress = true;
      state.submitMessage = "Laddar upp och tolkar texten på bilden...";

      api
        .scanOcr(data)
        .then((r) => {
          state.submitInProgress = false;
          console.log(r);
          state.result = r.text;
        })
        .catch((e) => {
          state.submitInProgress = false;
          console.log(e);
        }).finally(() => {
          state.submitMessage = "";
        });
    };

    return {
      state,
      validateForm,
      hasErrors,
      imageAdded,
      scan,
      // pretty,
      // hasMaxPictures,
      // hasMinPictures,
      // addPicture,
      // deletePicture,
      // imageRemoved,
      // send,
      // validateCode,
    };
  },
});
