<template>
  <div class="">
    <h3>Skriv för Nationellt Pistolskytte</h3>
    <p>Vi söker ständigt skribenter runt om i hela landet, även utomlands.</p>
    <!--<p>Om du vill skriva och få din artikel publicerad i tidningen har vi här samlat en bra guide för hur du kommer igång.</p>-->
    <p>
      Kontakta oss på
      <a href="mailto:np@liteolika.se?subject=Skriva för NP">np@liteolika.se</a>
      om du är intresserad.
    </p>
  </div>
</template>
