
import { computed, defineComponent, reactive } from "vue";
import profilestore from "@/stores/modules/profile";
// import { TextField } from "@/components";

interface IState {
  newPassword: string;
  newPasswordVerification: string;
}

export default defineComponent({
  components: {
    // TextField,
  },
  setup() {
    const state = reactive<IState>({
      newPassword: "",
      newPasswordVerification: "",
    });

    const displayName = computed(() => profilestore.fullname);

    return {
      state,
      displayName,
    };
  },
});
