
import { defineComponent } from "vue";

const Component = defineComponent({
  props: {
    message: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    loadingMessage(): string {
      return this.message as string;
    },
    loadingState(): boolean {
      return this.loading as boolean;
    },
  },
});

export default Component;
