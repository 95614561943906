/* eslint-disable no-bitwise */

export const getEmptyGuid = (): string =>
  "00000000-0000-0000-0000-000000000000";

export const getNewGuid = (): string =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r: number = (Math.random() * 16) | 0;
    const v: number = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
