import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30e41083"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker = _resolveComponent("datepicker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: _ctx.field,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createVNode(_component_datepicker, {
      class: "form-control form-control-sm",
      modelValue: _ctx.picked,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.picked) = $event)),
      locale: _ctx.sv,
      startingView: _ctx.startingView
    }, null, 8, ["modelValue", "locale", "startingView"])
  ]))
}