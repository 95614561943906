
import { api, ICreateUserAccount } from "@/services/api";
import {
  objectValidator,
  PasswordStrength,
  SameAs,
  generatePassword,
  MinArrayLength,
} from "@/utils";
import { IsEmail, IsNotEmpty, MinLength } from "class-validator";
import { defineComponent, reactive, computed, onMounted } from "vue";
import { TextField, FieldErrors } from "@/components";
import router from "@/router";

class Form implements ICreateUserAccount {
  @IsNotEmpty({ message: "Saknas" })
  @MinLength(2, { message: "För kort" })
  firstname = "";

  @IsNotEmpty({ message: "Saknas" })
  @MinLength(2, { message: "För kort" })
  lastname = "";

  @IsNotEmpty({ message: "Saknas" })
  @IsEmail(undefined, { message: "Inte en korrekt e-postadress" })
  email = "";

  @IsNotEmpty({ message: "Saknas" })
  @PasswordStrength({ message: "Lösenordet är inte tillräckligt komplicerat." })
  password = "";

  @IsNotEmpty({ message: "Saknas" })
  @SameAs("password", { message: "Lösenorden matchar inte" })
  passwordValidation = "";

  @MinArrayLength(1, { message: "Minst en roll måste väljas." })
  roles = [];
}

interface State {
  form: Form;
  errors: Record<string, any>;
  availableRoles: string[];
}

export default defineComponent({
  components: { TextField, FieldErrors },
  props: {},
  setup() {
    const state = reactive<State>({
      form: new Form(),
      errors: {},
      availableRoles: [],
    });

    const hasErrors = computed(() => Object.keys(state.errors).length !== 0);

    const validate = async () => {
      state.errors = await objectValidator(state.form);
    };

    const getGeneratedPassword = async () => {
      const password = await generatePassword();
      state.form.password = password;
      state.form.passwordValidation = password;
      validate();
    };

    const createAccount = async () => {
      if (hasErrors.value) return;

      api.createAccount(state.form).then(() => {
        router.push({ name: "useraccounts" });
      });
    };

    onMounted(async () => {
      state.availableRoles = await api.getRoles();
      await validate();
    });

    return {
      state,
      validate,
      hasErrors,
      getGeneratedPassword,
      createAccount,
    };
  },
});
