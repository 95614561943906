
import { defineComponent, onMounted } from "vue";
import router from "@/router";

export default defineComponent({
  setup() {
    onMounted(() => {
      console.log(router.currentRoute.value.redirectedFrom);
      console.log(router.currentRoute.value.fullPath);
    });
  },
});
