
/* eslint-disable function-paren-newline */
import { adstore } from "@/stores";
import { Modal } from "bootstrap";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  reactive,
} from "vue";
import { MultiFilterSelector, SorterDirection } from "@/components";
import loadspinner from "@/components/shared/loadspinner.vue";
import { api, IAdBookingView, IEdition } from "@/services";
import router from "@/router";

interface State {
  bookings: IAdBookingView[];
  editions: IEdition[];
  editionIdToExportAdBookingsFor: string | null;
  exportModal: Modal | null;
  exportingData: boolean;
}

interface IFilters {
  year: string[];
  edition: string[];
  status: string[];
  advertiser: string[];
  format: string[];
}

interface ISortingDesc {
  year: boolean;
  edition: boolean;
  status: boolean;
  advertiser: boolean;
  format: boolean;
}

export default defineComponent({
  components: {
    MultiFilterSelector,
    SorterDirection,
    loadspinner,
  },
  setup() {
    const state = reactive<State>({
      bookings: [],
      editions: [],
      editionIdToExportAdBookingsFor: null,
      exportingData: false,
      exportModal: null,
    });

    const bookings = reactive<IAdBookingView[]>([]);

    const filters = reactive<IFilters>({
      year: [],
      edition: [],
      status: [],
      advertiser: [],
      format: [],
    });

    const sorting = reactive({
      field: "",
      isDesc: false,
    });

    const filteredBookings = computed(() => {
      let filtered = bookings;

      if (filters.year.length > 0) {
        filtered = filtered.filter((f) => filters.year.indexOf(f.year) !== -1);
      }

      if (filters.edition.length > 0) {
        filtered = filtered.filter(
          (f) => filters.edition.indexOf(f.edition) !== -1
        );
      }

      if (filters.status.length > 0) {
        filtered = filtered.filter(
          (f) => filters.status.indexOf(f.status) !== -1
        );
      }

      if (filters.advertiser.length > 0) {
        filtered = filtered.filter(
          (f) => filters.advertiser.indexOf(f.advertiser) !== -1
        );
      }

      if (filters.format.length > 0) {
        filtered = filtered.filter(
          (f) => filters.format.indexOf(f.format) !== -1
        );
      }

      if (sorting.field === "asc") {
        return filtered;
      }

      if (sorting.field !== "") {
        return filtered.sort((a: any, b: any) => {
          if (sorting.isDesc) {
            if (a[sorting.field] < b[sorting.field]) return -1;
            return 1;
          }
          if (a[sorting.field] > b[sorting.field]) return -1;
          return 1;
        });
      }

      return filtered;
    });

    onMounted(async () => {
      const items = await api.getAdBookings();
      bookings.slice(0, bookings.length);
      bookings.push(...items);

      const editions = await api.getEditions();
      state.editions = editions;

      state.exportModal = new Modal(document.getElementById("exportModal")!, {
        keyboard: false,
        focus: true,
        backdrop: "static",
      });
    });

    const clearFilters = (field: string) => {
      const fs = filters as any;
      if (fs[field] && fs[field].length > 0) {
        fs[field].splice(0, fs[field].length);
      }
    };

    const setFilters = (field: string, values: string[]) => {
      const fs = filters as any;
      fs[field].push(...values);
    };

    const saveFilters = () => {
      localStorage.setItem("filters.adbookings", JSON.stringify(filters));
    };

    const filterApplied = (values: string[], field: string | undefined) => {
      if (field !== undefined) {
        clearFilters(field);
        setFilters(field, values);
        saveFilters();
      }
    };

    const loadFilters = () => {
      const savedFiltersJson = localStorage.getItem("filters.adbookings");
      if (savedFiltersJson !== null) {
        const savedFilters = JSON.parse(savedFiltersJson) as IFilters;
        clearFilters("year");
        setFilters("year", savedFilters.year);

        clearFilters("edition");
        setFilters("edition", savedFilters.edition);

        clearFilters("status");
        setFilters("status", savedFilters.status);

        clearFilters("advertiser");
        setFilters("advertiser", savedFilters.advertiser);

        clearFilters("format");
        setFilters("format", savedFilters.format);
      }
    };

    const sortOrderApplied = (desc: boolean, field: string) => {
      sorting.field = field;
      sorting.isDesc = desc;
    };

    const editAdBooking = async (bookingId: string) => {
      router.push({ name: "editAdBooking", params: { bookingId } });
    };

    const toggleExportModal = (show: boolean) => {
      if (show === true) {
        state.exportModal!.show();
      } else {
        state.exportModal!.hide();
      }
    };

    const exportBookingsToExcel = async () => {
      if (state.editionIdToExportAdBookingsFor == null) {
        return;
      }

      const edition = state.editions.filter(
        (x) => x.editionId === state.editionIdToExportAdBookingsFor
      )[0];

      if (edition === null) {
        return;
      }

      state.exportingData = true;

      api
        .exportAdBookingsToExcel(state.editionIdToExportAdBookingsFor)
        .then((blobdata: Blob) => {
          state.exportingData = false;
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blobdata);
          link.download = `Annonsbokningar_${edition.year}-${edition.edition}.xlsx`;
          link.click();
          URL.revokeObjectURL(link.href);
          state.exportModal!.hide();
        })
        .catch((e) => {
          alert("Error downloading report.");
          state.exportModal!.hide();
        });
    };

    loadFilters();

    return {
      state,
      bookings,
      filteredBookings,
      sorting,
      filterApplied,
      sortOrderApplied,
      editAdBooking,
      filters,
      exportBookingsToExcel,
      toggleExportModal,
    };
  },
});
